import { getManagers } from './actions';

export default (state = { managers: [] }, action) => {
  switch (action.type) {
    case getManagers.SUCCESS:
      return Object.assign({}, state, { managers: action.payload.managers });
    case getManagers.FAILURE:
      return state;
    default:
      return state;
  }
};
