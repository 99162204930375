import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faSync,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import Route from '../../components/route';
import Nav from '../../components/nav';
import DashboardPage from '../dashboard';
import LoginPage from '../login';
import LogoutPage from '../logout';
import RegisterPage from '../register';
import RegisterConfirmPage from '../register-confirm';
import ForgotPasswordPage from '../forgot-password';
import ConfirmPasswordPage from '../confirm-password';
import ResetPasswordPage from '../reset-password';
import CreateStampPage from '../stamps/new';
import VerifyStampPage from '../stamps/verify';
import DisputeStampPage from '../stamps/dispute';
import ViewStampsPage from '../stamps/view';
import ViewCoursePage from '../courses/view';
import ListCoursesPage from '../courses/list';
import VerifyCourseEnrolmentPage from '../courses/enrolment-verify';
import DisputeCourseEnrolmentPage from '../courses/enrolment-dispute';
import AddManagersPage from '../add-manager';
import { init } from '../../store/app/actions';
import { changeLocale } from '../../store/i18n/actions';

// Add the icons we want to use from font-awesome
library.add(faCheckCircle, faExclamationCircle, faSync, faCircle);

// Main single page app entry point and router. Relies upon the "loaded" state
// from the redux-async-initial-state middleware to determine whether or not to
// render the router. This is necessary to handle cases where the initial load
// is on a protected route. In such cases we need to asynchronously load the
// session state from Cognito and would redirect to the login page before that
// request completed.
const App = React.memo(({
  authenticated,
  isEmployee,
  isOrganisation,
  loaded,
  onMount,
  onChangeLocale,
  locale,
  busy,
}) => {
  useEffect(() => {
    onMount();
  }, [loaded]);

  const containerClassNames = authenticated
    ? 'container-fluid p-3 p-sm-4'
    : 'container container-xxs text-center px-3 px-sm-5 pt-5';

  return (
    <React.Fragment>
      {authenticated && <Helmet><body className="bg-light" /></Helmet>}
      {busy && <div className="busy" />}
      <Nav
        locale={locale}
        changeLocale={onChangeLocale}
        authenticated={authenticated}
        isEmployee={isEmployee}
        isOrganisation={isOrganisation}
      />
      <div className={containerClassNames}>
        {loaded && (
          <React.Fragment>
            <Route
              exact
              path="/"
              component={DashboardPage}
              authenticated={authenticated}
              requiresAuth
            />
            <Route
              exact
              path="/login"
              component={LoginPage}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/logout"
              component={LogoutPage}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/register"
              component={RegisterPage}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/register/confirm"
              component={RegisterConfirmPage}
              authenticated={authenticated}
            />
            <Route
              path="/confirm-password"
              component={ConfirmPasswordPage}
              authenticated={authenticated}
              exact
            />
            <Route
              exact
              path="/forgotten-password"
              component={ForgotPasswordPage}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/forgotten-password/confirm"
              component={ResetPasswordPage}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/stamps"
              component={ViewStampsPage}
              authenticated={authenticated}
              requiresAuth
            />
            <Route
              path="/stamps/new"
              component={CreateStampPage}
              authenticated={authenticated}
              requiresAuth
            />
            <Route
              path="/stamps/:stampId/verify"
              component={VerifyStampPage}
              authenticated={authenticated}
            />
            <Route
              path="/stamps/:stampId/dispute"
              component={DisputeStampPage}
              authenticated={authenticated}
            />
            <Route
              path="/courses"
              component={ListCoursesPage}
              authenticated={authenticated}
              exact
            />
            <Route
              path="/courses/:courseId"
              component={ViewCoursePage}
              authenticated={authenticated}
              exact
            />
            <Route
              path="/courses/:courseId/verify"
              component={VerifyCourseEnrolmentPage}
              authenticated={authenticated}
              exact
            />
            <Route
              path="/courses/:courseId/reject"
              component={DisputeCourseEnrolmentPage}
              authenticated={authenticated}
              exact
            />
            <Route
              path="/managers"
              component={AddManagersPage}
              authenticated={authenticated}
              exact
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  isOrganisation: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  busy: PropTypes.bool.isRequired,
};

function mapStateToProps({
  asyncInitialState,
  busy,
  login: {
    authenticated,
    groups,
  },
  i18n: {
    locale,
  },
}) {
  return {
    isEmployee: groups && groups.includes('employees'),
    isOrganisation: groups && groups.includes('organisations'),
    loaded: asyncInitialState.loaded,
    authenticated,
    locale,
    busy,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: () => dispatch(init()),
    onChangeLocale: (...args) => dispatch(changeLocale(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
