import BaseService from '../base';

class StampService extends BaseService {
  async create(data) {
    const {
      timePeriodStart,
      timePeriodEnd,
      minutes = '0',
      hours = '0',
      emailAddresses,
      skills,
      course,
      module,
    } = data;

    const totalTime = (parseInt(hours, 10) * 60) + parseInt(minutes, 10);
    await this.post('/stamps', {
      start: timePeriodStart,
      end: timePeriodEnd,
      minutes: totalTime,
      emailAddresses,
      skills,
      course,
      module,
    });

    return {
      success: true,
    };
  }

  // TODO: it would be nice to remove the dependency on email address here and
  // in the dispute method. We currently rely upon it (and effectively ignore
  // the stampId) in the backend to give consistency across HTTP and SNS (SMS)
  // interfaces.
  async verify(stampId, emailAddress) {
    await this.post(`/stamps/${stampId}/verify`, {
      emailAddress,
    });

    return {
      success: true,
    };
  }

  async dispute(stampId, emailAddress) {
    await this.post(`/stamps/${stampId}/dispute`, {
      emailAddress,
    });

    return {
      success: true,
    };
  }

  async get() {
    let stamps = [];

    stamps = await super.get('/stamps');

    return {
      success: true,
      ...stamps,
    };
  }
}

let instance;

export default (...args) => {
  if (!instance) {
    instance = new StampService(...args);
  }

  return instance;
};
