import BaseService from '../base';

class CourseService extends BaseService {
  async get() {
    let courses = [];

    courses = await super.get('/courses');

    return {
      success: true,
      ...courses,
    };
  }

  async getById(id) {
    const res = await super.get(`/courses/${id}`);

    return {
      success: true,
      course: res.course,
    };
  }

  async enrol(courseId) {
    await super.post(`/courses/${courseId}/enrol`);

    return {
      success: true,
      courseId,
    };
  }

  async verifyEnrolment(courseId, userId) {
    await super.post(`/courses/${courseId}/verify`, {
      userId,
    });

    return {
      success: true,
    };
  }

  async disputeEnrolment(courseId, userId) {
    await super.post(`/courses/${courseId}/dispute`, {
      userId,
    });

    return {
      success: true,
    };
  }
}

let instance;

export default (...args) => {
  if (!instance) {
    instance = new CourseService(...args);
  }

  return instance;
};
