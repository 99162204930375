import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { FormFeedback } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

// Utility function to split pasted input text into tags.
function onPaste(data) {
  return data.split(',').map(item => item.trim());
}

// Wrapper for the third party react-tagsinput input component
// that implements the redux-form field interface.
//
// Reference: https://redux-form.com/7.4.2/docs/api/field.md/
export default class TagFormInput extends React.PureComponent {
  static propTypes = {
    ...fieldPropTypes,
  };

  render() {
    const {
      meta: {
        touched,
        error,
      },
      input,
      className,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <TagsInput
          value={input.value || []}
          onChange={tags => input.onChange(tags)}
          addKeys={[9, 13, ',']} // Tab, enter and comma
          addOnPaste
          addOnBlur
          inputProps={{ placeholder: '' }}
          pasteSplit={onPaste}
          {...rest}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </React.Fragment>
    );
  }
}
