import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Table,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getManagers } from '../../../store/managers/actions';

const ManagerTable = ({
  getManagers, // eslint-disable-line no-shadow
  managers,
}) => {
  useEffect(() => {
    getManagers();
  }, []);

  const noManagersMessage = (
    <FormattedMessage
      id="Mamnager.Table.EmptyAdvice"
      tagName="p"
      defaultMessage="Once a manager has verified their account it will be displayed here."
    />
  );

  return (
    <div className="pt-4">
      <h5 className="mb-4">
        <FormattedMessage id="Manager.Table.Title" defaultMessage="Registered managers" />
      </h5>
      <React.Fragment>
        <Card>
          <CardBody>
            {managers ? (
              <Table>
                <thead>
                  <tr>
                    <th>Username</th>
                  </tr>
                </thead>
                {managers.map(manager => (
                  <tr key={manager.id}>
                    <td>
                      {manager.emailAddress}
                    </td>
                  </tr>
                ))}
              </Table>
            ) : noManagersMessage}
          </CardBody>
        </Card>
      </React.Fragment>
    </div>
  );
};

ManagerTable.propTypes = {
  getManagers: PropTypes.func.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

ManagerTable.defaultProps = {
  managers: [],
};

function mapStateToProps({ managers: { managers } }) {
  return {
    managers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getManagers: () => dispatch(getManagers()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerTable);
