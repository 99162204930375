import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModuleList from '../module-list';

function EmployeeViewCoursePage({
  course,
  courseStamps,
}) {
  return (
    <div className="page-course-view box p-3 p-sm-5">
      <h2>{course.name}</h2>
      <div className="modules">
        <ModuleList course={course} stamps={courseStamps} showStampButtons />
      </div>
    </div>
  );
}

EmployeeViewCoursePage.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  courseStamps: PropTypes.arrayOf(PropTypes.shape({})),
};

EmployeeViewCoursePage.defaultProps = {
  course: {},
  courseStamps: [],
};

function mapStateToProps({ stamps: { stamps } }, { course }) {
  if (!course || !course.id) {
    return {};
  }

  const courseStamps = stamps.reduce((arr, stamp) => {
    if (stamp.course && stamp.module && stamp.course === course.id) {
      arr.push(stamp);
    }

    return arr;
  }, []);

  return {
    courseStamps,
  };
}

export default connect(mapStateToProps)(EmployeeViewCoursePage);
