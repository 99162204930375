import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { getStamps } from '../../../store/stamps/actions';
import Stamp from './stamp';

function ViewStampPage({ stamps, getStamps }) { // eslint-disable-line no-shadow
  useEffect(getStamps, []);

  return (
    <div className="page-stamp-view container-sm">
      <Helmet><body className="override-container" /></Helmet>
      {stamps && stamps.map(stamp => (
        <Stamp stamp={stamp} key={stamp.id} />
      ))}
    </div>
  );
}

ViewStampPage.propTypes = {
  getStamps: PropTypes.func.isRequired,
  stamps: PropTypes.arrayOf(PropTypes.shape({
    phoneNumber: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  })),
};

ViewStampPage.defaultProps = {
  stamps: [],
};

function mapStateToProps({ stamps: { stamps } }) {
  // Sort stamps by created date.
  stamps.sort((a, b) => b.createdAt - a.createdAt);
  return {
    stamps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStamps: () => dispatch(getStamps()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewStampPage);
