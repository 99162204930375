import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import CourseListItem from '../../../../components/course-list-item';

function ListCoursesEmployee({
  courses,
  enrol,
}) {
  const [visibleCourses, setVisibleCourses] = useState(courses);

  useEffect(() => {
    setVisibleCourses(courses);
  }, [courses]);

  // Helper function to filter the list.
  function search(term) {
    setVisibleCourses(courses.filter((course) => {
      const match = term.toLowerCase();

      return course.name.toLowerCase().includes(match)
      || course.organisation.toLowerCase().includes(match);
    }));
  }

  // Render a list of available courses.
  let coursesList;

  if (visibleCourses.length) {
    coursesList = visibleCourses.map(course => (
      <CourseListItem course={course} handleEnrolment={enrol} />
    ));
  }

  return (
    <div>
      <Input size="lg" className="mb-4" placeholder="Start typing to search..." onChange={e => search(e.target.value)} />
      <div className="card-columns">{coursesList}</div>
    </div>
  );
}

ListCoursesEmployee.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  enrol: PropTypes.func.isRequired,
};

ListCoursesEmployee.defaultProps = {
  courses: [],
};

export default ListCoursesEmployee;
