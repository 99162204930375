import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import {
  getCourse,
  getCourses,
  enrolCourse,
  verifyCourseEnrolment,
  disputeCourseEnrolment,
} from './actions';
import getCourseService from '../../services/course';
import { intl } from '../../containers/i18n';

const getCourseMessages = defineMessages({
  generic: {
    id: 'GetCourse.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const getCoursesMessages = defineMessages({
  generic: {
    id: 'GetCourses.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const enrolCourseMessages = defineMessages({
  generic: {
    id: 'EnrolCourses.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const verifyCourseEnrolmentMessages = defineMessages({
  generic: {
    id: 'VerifyCourseEnrolment.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const disputeCourseEnrolmentMessages = defineMessages({
  generic: {
    id: 'DisputeCourseEnrolment.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

export function* handleGetCourses() {
  try {
    const courseService = getCourseService();

    yield put(getCourses.request());

    const res = yield apply(courseService, courseService.get);

    yield put(getCourses.success(res));
  } catch (err) {
    const message = getCoursesMessages[err.code] || getCoursesMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(getCourses.failure(error));
  } finally {
    yield put(getCourses.fulfill());
  }
}

export function* handleGetCourse(action) {
  try {
    const courseService = getCourseService();

    yield put(getCourse.request());

    const res = yield apply(courseService, courseService.getById, [
      action.payload,
    ]);

    yield put(getCourse.success(res));
  } catch (err) {
    const message = getCourseMessages[err.code] || getCourseMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(getCourse.failure(error));
  } finally {
    yield put(getCourse.fulfill());
  }
}

export function* handleEnrolCourse(action) {
  try {
    const courseService = getCourseService();

    yield put(enrolCourse.request());

    const res = yield apply(courseService, courseService.enrol, [
      action.payload,
    ]);

    yield put(enrolCourse.success(res));
  } catch (err) {
    const message = enrolCourseMessages[err.code] || enrolCourseMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(enrolCourse.failure(error));
  } finally {
    yield put(enrolCourse.fulfill());
  }
}

export function* handleVerifyCourseEnrolment(action) {
  try {
    const courseService = getCourseService();

    yield put(verifyCourseEnrolment.request());

    const res = yield apply(courseService, courseService.verifyEnrolment, [
      action.payload.courseId,
      action.payload.userId,
    ]);

    yield put(verifyCourseEnrolment.success(res));
  } catch (err) {
    const message = verifyCourseEnrolmentMessages[err.code]
      || verifyCourseEnrolmentMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(verifyCourseEnrolment.failure(error));
  } finally {
    yield put(verifyCourseEnrolment.fulfill());
  }
}

export function* handleDisputeCourseEnrolment(action) {
  try {
    const courseService = getCourseService();

    yield put(disputeCourseEnrolment.request());

    const res = yield apply(courseService, courseService.disputeEnrolment, [
      action.payload.courseId,
      action.payload.userId,
    ]);

    yield put(disputeCourseEnrolment.success(res));
  } catch (err) {
    const message = disputeCourseEnrolmentMessages[err.code]
      || disputeCourseEnrolmentMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(disputeCourseEnrolment.failure(error));
  } finally {
    yield put(disputeCourseEnrolment.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(getCourse.TRIGGER, handleGetCourse),
    takeLatest(getCourses.TRIGGER, handleGetCourses),
    takeLatest(enrolCourse.TRIGGER, handleEnrolCourse),
    takeLatest(verifyCourseEnrolment.TRIGGER, handleVerifyCourseEnrolment),
    takeLatest(disputeCourseEnrolment.TRIGGER, handleDisputeCourseEnrolment),
  ]);
}
