/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedDateParts } from 'react-intl';
import StampStatusIcon from '../stamp-status';

function getDate(date) {
  return (
    // For info on formatting dates refer to
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts
    <FormattedDateParts
      value={date}
      year="numeric"
      month="long"
      day="2-digit"
    >
      {parts => (
        <>
          {parts[2].value}
          {parts[1].value}
          {parts[0].value}
          {parts[1].value}
          {parts[4].value}
        </>
      )}
    </FormattedDateParts>
  );
}

function Stamp({ stamp }) {
  const hours = Math.floor(stamp.minutes / 60);
  let minutes = stamp.minutes % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return (
    <div className="stamp box p-sm-4">
      <div className="stamp-title">
        <Row>
          <Col md="4">
            <h6 className="text-muted"><small>Time period</small></h6>
            <p>{getDate(stamp.start)} - {getDate(stamp.end)}</p>
          </Col>
          <Col md="2" sm="6">
            <h6 className="text-muted"><small>Hours</small></h6>
            <p>{hours}:{minutes}</p>
          </Col>
          <Col md="2" sm="6">
            <h6 className="text-muted"><small>Status</small></h6>
            <StampStatusIcon status={stamp.status} />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h6 className="text-muted"><small>Approver</small></h6>
            <p>{stamp.recipient[stamp.contactMethod]}</p>
          </Col>
          <Col md="4">
            <h6 className="text-muted"><small>Sent By</small></h6>
            <p>{stamp.createdBy[stamp.contactMethod]}</p>
          </Col>
          <Col md="4">
            <h6 className="text-muted"><small>Sent Date</small></h6>
            <p>{getDate(stamp.createdAt)}</p>
          </Col>
        </Row>
      </div>
      <div className="tag-group">
        {stamp.skills.map(skill => (
          <div key={skill} className="tag-item">{skill}</div>
        ))}
      </div>
    </div>
  );
}

Stamp.propTypes = {
  stamp: PropTypes.shape({
    recipientType: PropTypes.string,
  }).isRequired,
};

export default Stamp;
