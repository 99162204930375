import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Amplify from 'aws-amplify';
import GA from 'react-ga';
import App from './containers/app';
import I18n from './containers/i18n';
import store, { history } from './store';
import Config from './config';

import './styles/index.scss';

// Configure Google Analytics.
GA.initialize(Config.GoogleAnalytics.TRACKING_CODE);

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    region: Config.Aws.REGION,
    userPoolId: Config.Aws.Cognito.USER_POOL_ID,
    identityPoolId: Config.Aws.Cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: Config.Aws.Cognito.USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: Config.Aws.Cognito.Cookie.DOMAIN,
      secure: Config.Aws.Cognito.Cookie.SECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: 'tendo',
        endpoint: Config.Aws.APIGateway.URL,
        region: Config.Aws.REGION,
      },
    ],
  },
});

// Get user language from the browser. Different browsers expose this in
// different ways, hence the complex conditional. The value can be a generic
// language code (such as "en" for English) or a more specific code (such as
// "en-US" for American English). We currently only handle generic languages so
// we normalise specific codes to generic ones.
const language = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage;
const genericLanguage = language.toLowerCase().split(/[_-]+/)[0];

// Render the client-side React application.
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18n defaultLocale={genericLanguage}>
        <App />
      </I18n>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
