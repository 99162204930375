import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label, Input } from 'reactstrap';
import CreateStampForm from './form';
import { getCourses, getCourse } from '../../../store/courses/actions';

const CreateStampPage = ({
  user,
  courses,
  location,
  getCourse, // eslint-disable-line no-shadow
  getCourses, // eslint-disable-line no-shadow
}) => {
  const initialCourseId = (location.state && location.state.courseId) || null;
  const initialModuleId = (location.state && location.state.moduleId) || null;
  const [selectedCourse, changeSelectedCourse] = useState(initialCourseId);
  const [selectedModule, changeSelectedModule] = useState(initialModuleId);

  // Fetch courses from the server.
  //
  // TODO: ensure we don't do this unnecessarily (if we already have the course
  // data in the store).
  useEffect(() => {
    const isEmployee = user.groups.includes('employees');

    if (isEmployee) {
      getCourses();
    }
  }, [getCourses]);

  // Fetch modules for each course for which we do not already have modules from
  // the server.
  useEffect(() => {
    const isEmployee = user.groups.includes('employees');

    if (isEmployee && courses.length) {
      courses.forEach((course) => {
        if (!course.modules) {
          getCourse(course.id);
        }
      });
    }
  }, [getCourse, courses]);

  // If the user is enrolled in any courses we show an additional control that
  // allows them to create course-linked stamps here.
  let typeToggle;

  if (courses.length) {
    let moduleSelect;

    // If a course is selected we render a select box to allow the user to
    // choose the relevant module from the course.
    if (selectedCourse !== null) {
      const course = courses.find(c => c.id === selectedCourse);

      if (course.modules) {
        moduleSelect = (
          <div className="stamp-course-module-select">
            <FormGroup>
              <Label for="exampleSelect">Course module</Label>
              <Input
                type="select"
                name="stamp-course-module"
                bsSize="lg"
                onChange={e => changeSelectedModule(e.target.value)}
                defaultValue={selectedModule}
              >
                {course.modules.map(module => (
                  <option key={module.id} value={module.id}>{module.name}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
        );
      }
    }

    typeToggle = (
      <React.Fragment>
        <div className="stamp-type-toggle">
          <form>
            <FormGroup check>
              <Label>
                <Input
                  type="radio"
                  name="stamp-type-toggle"
                  value={0}
                  checked={selectedCourse === null}
                  onChange={() => {
                    changeSelectedCourse(null);
                    changeSelectedModule(null);
                  }}
                />
                Regular Stamp
              </Label>
            </FormGroup>
            {courses.map(course => (
              <FormGroup key={course.id} check>
                <Label>
                  <Input
                    type="radio"
                    name="stamp-type-toggle"
                    value={course.id}
                    checked={selectedCourse === course.id}
                    onChange={() => {
                      changeSelectedCourse(course.id);
                      changeSelectedModule(course.modules[0].id);
                    }}
                  />
                  {course.name} Stamp
                </Label>
              </FormGroup>
            ))}
          </form>
        </div>
        {moduleSelect}
      </React.Fragment>
    );
  }

  return (
    <div className="page-stamp-create container-sm box p-3 p-sm-5">
      <FormattedMessage
        id="Stamps.Create.Title"
        defaultMessage="Issue a Stamp"
      >
        {message => <h2 className="mb-4">{message}</h2>}
      </FormattedMessage>
      {typeToggle}
      <CreateStampForm user={user} course={selectedCourse} module={selectedModule} />
    </div>
  );
};

CreateStampPage.propTypes = {
  getCourses: PropTypes.func.isRequired,
  getCourse: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groups: PropTypes.arrayOf({}),
  }).isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  location: PropTypes.shape({
    state: PropTypes.shape({
      courseId: PropTypes.string,
      moduleId: PropTypes.string,
    }),
  }).isRequired,
};

CreateStampPage.defaultProps = {
  courses: [],
};

function mapStateToProps({ login, courses: { courses } }) {
  const userCourses = courses.filter(course => course.enrolmentStatus === 'verified');

  return {
    courses: userCourses,
    user: {
      groups: login.groups,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCourses: () => dispatch(getCourses()),
    getCourse: id => dispatch(getCourse(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStampPage);
