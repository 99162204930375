import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

export const registerOrganisation = createRoutine('REGISTER_ORGANISATION');
export const registerOrganisationFormHandler = bindRoutineToReduxForm(registerOrganisation);
export const registerEmployee = createRoutine('REGISTER_EMPLOYEE');
export const registerEmployeeFormHandler = bindRoutineToReduxForm(registerEmployee);
export const registerManager = createRoutine('REGISTER_MANAGER');
export const registerManagerFormHandler = bindRoutineToReduxForm(registerManager);
export const confirmRegistration = createRoutine('REGISTER_CONFIRM');
export const confirmRegistrationFormHandler = bindRoutineToReduxForm(confirmRegistration);
export const verifyRegistration = createRoutine('REGISTER_VERIFY');
