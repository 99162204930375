import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormGroup, Alert } from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { confirmPasswordFormHandler } from '../../../store/login/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const ConfirmPasswordForm = ({
  handleSubmit,
  pristine,
  error,
  invalid,
  submitting,
  intl,
}) => (
  <form onSubmit={handleSubmit(confirmPasswordFormHandler)}>
    {error && <Alert color="danger">{error.message}</Alert>}
    <FormGroup>
      <Field
        component={FormInput}
        name="password"
        id="password"
        type="password"
        bsSize="lg"
        validate={[requiredField]}
        placeholder={intl.formatMessage({
          id: 'ConfirmPassword.Form.Email',
          defaultMessage: 'Password',
        })}
        required
      />
    </FormGroup>
    <Button
      type="submit"
      size="lg"
      disabled={invalid || pristine || submitting}
      color="primary"
      block
    >
      <FormattedMessage id="ConfirmPassword.Form.Send" defaultMessage="Submit" />
    </Button>
  </form>
);

ConfirmPasswordForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'ConfirmPassword.Form',
})(ConfirmPasswordForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withReduxForm);

export default withIntl;
