import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourse } from '../../../store/courses/actions';
import { getStamps } from '../../../store/stamps/actions';
import ManagerPage from './manager';
import EmployeePage from './employee';

function ViewCoursePage({
  isEmployee,
  course,
  getCourse, // eslint-disable-line no-shadow
  getStamps, // eslint-disable-line no-shadow
  match: { params: { courseId } },
}) {
  useEffect(() => {
    getCourse(courseId);
    getStamps();
  }, [getCourse, getStamps, courseId]);

  const commonProps = { course };
  const component = isEmployee
    ? <EmployeePage {...commonProps} />
    : <ManagerPage {...commonProps} />;

  return <div className="container-sm">{component}</div>;
}

ViewCoursePage.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  course: PropTypes.shape({
    name: PropTypes.string,
  }),
  getCourse: PropTypes.func.isRequired,
  getStamps: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      courseId: PropTypes.string,
    }),
  }).isRequired,
};

ViewCoursePage.defaultProps = {
  course: null,
};

function mapStateToProps(
  { courses: { courses }, login },
  { match: { params: { courseId } } },
) {
  const course = courses.find(c => c.id === courseId);

  return {
    isEmployee: login.groups.includes('employees'),
    course,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCourse: courseId => dispatch(getCourse(courseId)),
    getStamps: () => dispatch(getStamps()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCoursePage);
