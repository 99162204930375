import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Progress } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ToggleButtonGroup from '../../../../components/toggle-button-group';
import ModuleList from '../module-list';

function ManagerViewCoursePage({ course, courseStampsByUser }) {
  const [selectedTab, changeSelectedTab] = useState(0);
  const totalModules = course && course.modules && course.modules.length;
  const hasUsers = course && course.enrolments && course.enrolments.length > 0;
  let progressIntro;
  let userProgress;
  let tab;

  if (hasUsers) {
    progressIntro = (
      <div className="course-progress-intro">
        <p>Below is an overview of how employees are progressing through the course.</p>
      </div>
    );
    userProgress = course.enrolments.map((enrolment) => {
      const user = enrolment && enrolment.user;
      const verified = enrolment && enrolment.status === 'verified';

      if (user && verified) {
        const stamps = courseStampsByUser[user.id];
        const completedModules = (stamps && stamps.length) || 0;
        const completedPercentage = Math.floor((completedModules / totalModules) * 100);
        let progressBar;

        if (!Number.isNaN(completedPercentage)) {
          progressBar = (
            <Progress value={completedPercentage}>
              {completedPercentage}%
            </Progress>
          );
        }

        return (
          <div key={user.id}>
            <p className="course-progress-user">{user.emailAddress}</p>
            {progressBar}
          </div>
        );
      }

      return null;
    });
  } else {
    progressIntro = (
      <div className="course-progress-intro">
        <FormattedMessage
          id="Courses.Manager.EmptyAdvice"
          tagName="p"
          defaultMessage="As soon as you begin to verify stamps issued by users enroled in this course, you will see an overview of course progression this page."
        />
      </div>
    );
  }

  if (selectedTab === 0) {
    tab = (
      <React.Fragment>
        <h2>{course.name}</h2>
        {progressIntro}
        {userProgress}
      </React.Fragment>
    );
  } else if (selectedTab === 1) {
    tab = (
      <div className="modules">
        <ModuleList course={course} showStampButtons={false} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ToggleButtonGroup
            buttonText={[
              <FormattedMessage id="Courses.Manager.ToggleCandidates" defaultMessage="Candidates" />,
              <FormattedMessage id="Courses.Manager.ToggleContent" defaultMessage="Content" />,
            ]}
            selectedIndex={selectedTab}
            onChange={changeSelectedTab}
            block
          />
        </Col>
      </Row>
      <div className="page-course-view box p-3 p-sm-5">
        {tab}
      </div>
    </React.Fragment>
  );
}

ManagerViewCoursePage.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.shape({})),
    enrolments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  courseStampsByUser: PropTypes.shape({}),
};

ManagerViewCoursePage.defaultProps = {
  course: {},
  courseStampsByUser: {},
};

function mapStateToProps({ stamps: { stamps } }, { course }) {
  if (!course || !course.id) {
    return {};
  }

  const courseStampsByUser = stamps.reduce((obj, stamp) => {
    if (stamp.course && stamp.module && stamp.course === course.id) {
      // eslint-disable-next-line no-param-reassign
      obj[stamp.createdBy.id] = obj[stamp.createdBy.id] || [];
      obj[stamp.createdBy.id].push(stamp);
    }

    return obj;
  }, {});

  return {
    course,
    courseStampsByUser,
  };
}

export default connect(mapStateToProps)(ManagerViewCoursePage);
