import { changeLocale } from './actions';
import { login } from '../login/actions';

const INITIAL_STATE = {
  locale: 'en',
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === changeLocale.SUCCESS) {
    return Object.assign({}, state, {
      locale: action.payload,
    });
  }

  if (action.type === login.SUCCESS) {
    return Object.assign({}, state, {
      locale: action.payload.locale,
    });
  }

  return state;
};
