import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonGroup, Button } from 'reactstrap';

// An implementation of a Bootstrap button group with Reactstrap. Includes the
// ability to stretch to the full width of its container via the "block" prop
//
// See: https://reactstrap.github.io/components/buttons/
const ToggleButtonGroup = ({
  block,
  buttonText,
  onChange,
  className,
  selectedIndex = null,
}) => {
  const groupClassNames = classNames(className, {
    'd-flex': block,
  });
  const buttonClassNames = classNames({
    'w-100': block,
  });

  return (
    <ButtonGroup className={groupClassNames}>
      {buttonText.map((text, i) => (
        <Button
          onClick={() => onChange(i)}
          active={selectedIndex === i}
          className={buttonClassNames}
          key={i} // eslint-disable-line react/no-array-index-key
        >
          {text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

ToggleButtonGroup.propTypes = {
  buttonText: PropTypes.arrayOf(PropTypes.node).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number, // eslint-disable-line react/require-default-props
  className: PropTypes.string, // eslint-disable-line react/require-default-props
  block: PropTypes.bool,
};

ToggleButtonGroup.defaultProps = {
  block: false,
};

export default ToggleButtonGroup;
