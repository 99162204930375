/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getStamps } from '../../../store/stamps/actions';
import { getCourses } from '../../../store/courses/actions';
import StampTable from '../../../components/stamp-table';

const ManagerDashboard = ({
  stampsByEmployee,
  courses,
  getStamps, // eslint-disable-line no-shadow
  getCourses, // eslint-disable-line no-shadow
}) => {
  useEffect(() => {
    getStamps();
    getCourses();
  }, [getStamps, getCourses]);

  // Determine if we need to display any course details. Only manager users who
  // are managers of a course will see this.
  let coursesSection;

  if (courses.length) {
    coursesSection = (
      <Row>
        <Col>
          <div className="courses box p-3 p-sm-5">
            <FormattedMessage
              id="Dashboard.Employee.Courses"
              defaultMessage="Courses"
              tagName="h2"
            />
            <ul>
              {courses.map(course => (
                <li key={course.id}>
                  <Link to={`/courses/${course.id}`}>{course.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  }

  if (!Object.keys(stampsByEmployee).length) {
    return (
      <div className="page-dashboard-manager">
        {coursesSection}
        <FormattedMessage
          id="Dashboard.Manager.EmptyAdvice"
          tagName="p"
          defaultMessage="As soon as your employees verify Stamps you have sent them, you will see an overview of your organisation on this page. Get started by {link}."
          values={{
            link: (
              <Link to="/stamps/new">
                <FormattedMessage
                  id="Dashboard.Manager.EmptyAdvice.Link"
                  defaultMessage="sending a Stamp"
                />
              </Link>
            ),
          }}
        />
      </div>
    );
  }

  return (
    <div className="page-dashboard-manager">
      {coursesSection}
      <StampTable stamps={stampsByEmployee} />
    </div>
  );
};

ManagerDashboard.propTypes = {
  getStamps: PropTypes.func.isRequired,
  stampsByEmployee: PropTypes.objectOf(PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    totalTime: PropTypes.number,
  })),
  courses: PropTypes.arrayOf(PropTypes.shape({})),
};

ManagerDashboard.defaultProps = {
  stampsByEmployee: {},
  courses: [],
};

// Separate all stamps issued to employees of this manager into an object keyed
// on employee id. This allows us to display a work summary of each employee.
function getStampsByEmployee(stamps) {
  return stamps
    .filter(stamp => stamp.status === 'verified')
    .reduce((obj, stamp) => {
      let employee;
      let key;

      // If the stamp was issued by an manager to the employee we can use the
      // "recipient" property to group related stamps. If it was issued by the
      // employee we need to use the "createdBy" property instead.
      if (stamp.recipientType === 'employee') {
        key = stamp.recipient.id;
        employee = stamp.recipient;
      } else {
        key = stamp.createdBy.id;
        employee = stamp.createdBy;
      }

      if (!obj[key]) {
        obj[key] = { // eslint-disable-line no-param-reassign
          skills: [],
          totalTime: 0,
          employee,
        };
      }

      // Create a cumulative total of all time worked by the employee.
      obj[key].totalTime += parseInt( // eslint-disable-line no-param-reassign
        stamp.minutes,
        10,
      );

      // Create an array of all unique skills demonstrated by the employee.
      obj[key].skills = [...new Set( // eslint-disable-line no-param-reassign
        obj[key]
          .skills.concat(stamp.skills),
      )];

      return obj;
    }, {});
}

function mapStateToProps({ stamps: { stamps }, courses: { courses } }) {
  return {
    stampsByEmployee: getStampsByEmployee(stamps),
    courses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStamps: () => dispatch(getStamps()),
    getCourses: () => dispatch(getCourses()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard);
