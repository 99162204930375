import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  FormGroup,
  FormText,
  Alert,
} from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { registerEmployeeFormHandler } from '../../../store/register/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const EmployeeRegisterForm = ({
  handleSubmit,
  pristine,
  invalid,
  error,
  submitting,
  intl,
}) => (
  <form onSubmit={handleSubmit(registerEmployeeFormHandler)}>
    {error && <Alert color="danger">{error.message}</Alert>}
    <FormGroup>
      <Field
        component={FormInput}
        name="name"
        id="name"
        type="text"
        bsSize="lg"
        validate={[requiredField]}
        placeholder={intl.formatMessage({
          id: 'Register.Form.Employee.Name',
          defaultMessage: 'Full Name',
        })}
        required
      />
    </FormGroup>
    <FormGroup>
      <Field
        component={FormInput}
        name="email"
        id="email"
        type="text"
        bsSize="lg"
        validate={[requiredField]}
        placeholder={intl.formatMessage({
          id: 'Register.Form.Employee.Email',
          defaultMessage: 'Email Address',
        })}
        required
      />
    </FormGroup>
    <FormGroup>
      <Field
        component={FormInput}
        name="password"
        id="password"
        type="password"
        bsSize="lg"
        validate={[requiredField]}
        placeholder={intl.formatMessage({
          id: 'Register.Form.Employee.Password',
          defaultMessage: 'Account Password',
        })}
        required
      />
      <FormText>
        <FormattedMessage
          id="Register.Form.PasswordAdvice"
          defaultMessage="Your password must be at least 8 characters long."
        />
      </FormText>
    </FormGroup>
    <Button
      type="submit"
      size="lg"
      disabled={invalid || pristine || submitting}
      color="primary"
      className="my-4"
      block
    >
      <FormattedMessage id="Register.Form.Send" defaultMessage="Register" />
    </Button>
  </form>
);

EmployeeRegisterForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'Register.Form',
})(EmployeeRegisterForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withReduxForm);

export default withIntl;
