/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { verifyStamp } from '../../../store/stamps/actions';

const VerifyStampPage = ({
  success,
  error,
  verify,
  location: { search },
  match: { params: { stampId } },
}) => {
  useEffect(() => {
    const { email } = qs.parse(search);

    verify(stampId, email);
  }, []);

  const { type } = qs.parse(search);
  const message = type === 'employee'
    ? 'Success - Stamp verified. To view this record alongside all your other Stamps, <a href="/login">login</a> or <a href="/register">register</a> now.'
    : 'Success - Stamp verified. To view this record alongside all employees in your Tendo Business Account, <a href="/login">login</a> or <a href="/register">register</a> now.';

  return (
    <div className="page-stamp-verify">
      {success && <Alert color="success"><span dangerouslySetInnerHTML={{ __html: sanitize(message) }} /></Alert>}
      {error && <Alert color="danger">{error.message}</Alert>}
    </div>
  );
};

VerifyStampPage.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  verify: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      stampId: PropTypes.string,
    }),
  }).isRequired,
};

VerifyStampPage.defaultProps = {
  success: null,
  error: null,
};

function mapStateToProps({ stamps: { verify: { success, error } } }) {
  return { success, error };
}

function mapDispatchToProps(dispatch) {
  return {
    verify: (stampId, emailAddress) => dispatch(verifyStamp({ stampId, emailAddress })),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(VerifyStampPage);
