import { API, Auth } from 'aws-amplify';

export default class BaseService {
  async post(endpoint, data) { // eslint-disable-line class-methods-use-this
    const headers = {};
    let user;
    let token;

    try {
      user = await Auth.currentAuthenticatedUser();
      token = user.signInUserSession.idToken.jwtToken;
      headers.Authorization = token;
    } catch (err) {
      // Ignore error. This means we are unauthenticated. We allow this to
      // continue to handle API calls that can be made when unauthenticated,
      // such as stamp verification when clicking a link from an email.
    }

    return API.post('tendo', endpoint, {
      headers,
      body: data,
    }).catch((error) => {
      const err = new Error(error.response.data.message);

      err.code = error.response.data.code;
      throw err;
    });
  }

  async get(endpoint) { // eslint-disable-line class-methods-use-this
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    return API.get('tendo', endpoint, {
      headers: {
        Authorization: token,
      },
    }).catch((error) => {
      const err = new Error(error.response.data.message);

      err.code = error.response.data.code;
      throw err;
    });
  }
}
