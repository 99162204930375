/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sanitize } from 'dompurify';

function ModuleList({ course, stamps, showStampButtons }) {
  const stampsByModule = stamps.reduce((obj, stamp) => {
    obj[stamp.module] = stamp; // eslint-disable-line no-param-reassign
    return obj;
  }, {});

  return (
    <ol className="module-list">
      {course && course.modules && course.modules.map((module) => {
        const status = (stampsByModule[module.id] && stampsByModule[module.id].status)
          || 'incomplete';
        const hours = Math.floor(module.minutes / 60);
        let minutes = module.minutes % 60;

        if (minutes < 10) {
          minutes = `0${minutes}`;
        }

        let action;

        if (showStampButtons && status === 'incomplete') {
          action = (
            <Link
              to={{
                pathname: '/stamps/new',
                state: {
                  courseId: course.id,
                  moduleId: module.id,
                },
              }}
              className="btn btn-primary btn-lg btn-block"
            >
              Mark as complete
            </Link>
          );
        }

        return (
          <li key={module.id} className={status}>
            <strong className="module-name">{module.name}</strong>
            <p className="module-description" dangerouslySetInnerHTML={{ __html: sanitize(module.description) }} />
            <strong>Skills you&apos;ll develop</strong>
            <div className="tag-group">
              {module.skills.map(skill => (
                <div key={skill} className="tag-item">{skill}</div>
              ))}
            </div>
            <strong>Time required</strong>
            <p>{hours}:{minutes}</p>
            {action}
          </li>
        );
      })}
    </ol>
  );
}

ModuleList.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  stamps: PropTypes.arrayOf(PropTypes.shape({})),
  showStampButtons: PropTypes.bool,
};

ModuleList.defaultProps = {
  showStampButtons: true,
};

ModuleList.defaultProps = {
  course: {},
  stamps: [],
};

export default ModuleList;
