import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import { Auth } from 'aws-amplify';
import { defineMessages } from 'react-intl';
import { forgotPassword, resetPassword } from './actions';
import { intl } from '../../containers/i18n';

const forgotPasswordMessages = defineMessages({
  UserNotConfirmedException: {
    id: 'ForgotPassword.Error.UserNotConfirmed',
    defaultMessage: 'You have not yet confirmed your email address.',
  },
  generic: {
    id: 'ForgotPassword.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const resetPasswordMessages = defineMessages({
  ExpiredCodeException: {
    id: 'ResetPassword.Error.CodeExpired',
    defaultMessage: 'The verification code has expired. We\'ve sent a new one which will remain active for 24 hours.',
  },
  CodeMismatchException: {
    id: 'ResetPassword.Error.CodeMismatch',
    defaultMessage: 'The verification code you provided was incorrect.',
  },
  // This exception appears to be thrown by Cognito when certain password
  // requirements are not met. It's not immediately clear when this one is
  // thrown compared to the more-appropriately-named InvalidPasswordException.
  InvalidParameterException: {
    id: 'Register.Error.InvalidParameter',
    defaultMessage: 'Your password must be at least 8 characters long. It must include at least one lowercase letter, uppercase letter, number and symbol.',
  },
  InvalidPasswordException: {
    id: 'ResetPassword.Error.InvalidPassword',
    defaultMessage: 'Your password must be at least 8 characters long. It must include at least one lowercase letter, uppercase letter, number and symbol.',
  },
  generic: {
    id: 'ResetPassword.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

export function* handleForgotPassword(action) {
  try {
    yield put(forgotPassword.request());

    const res = yield apply(Auth, Auth.forgotPassword, [
      action.payload.values.email,
    ]);

    yield put(forgotPassword.success(res));
    yield put(push('/forgotten-password/confirm', {
      email: action.payload.values.email,
    }));
  } catch (err) {
    const message = forgotPasswordMessages[err.code] || forgotPasswordMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(forgotPassword.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(forgotPassword.fulfill());
  }
}

export function* handleResetPassword(action) {
  try {
    yield put(resetPassword.request());

    const res = yield apply(Auth, Auth.forgotPasswordSubmit, [
      action.payload.values.email,
      action.payload.values.code,
      action.payload.values.password,
    ]);

    yield put(resetPassword.success(res));
    yield put(push('/login', {
      action: 'RESET_PASSWORD',
    }));
  } catch (err) {
    const message = resetPasswordMessages[err.code] || resetPasswordMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(resetPassword.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(resetPassword.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(forgotPassword.TRIGGER, handleForgotPassword),
    takeLatest(resetPassword.TRIGGER, handleResetPassword),
  ]);
}
