import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getCourses, enrolCourse } from '../../../store/courses/actions';
import EmployeePage from './employee';
import ManagerPage from './manager';

function ListCoursesPage({
  isEmployee,
  getCourses, // eslint-disable-line no-shadow
  courses,
  enrol,
}) {
  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const commonProps = { courses };
  const component = isEmployee
    ? <EmployeePage enrol={enrol} {...commonProps} />
    : <ManagerPage {...commonProps} />;

  return (
    <div className="page-courses-list box p-3 p-sm-5">
      <FormattedMessage
        id="Dashboard.Employee.Courses"
        defaultMessage="Courses"
        tagName="h2"
      />
      {component}
    </div>
  );
}

ListCoursesPage.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  getCourses: PropTypes.func.isRequired,
  enrol: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

ListCoursesPage.defaultProps = {
  courses: [],
};

function mapStateToProps({ courses: { courses }, login }) {
  return {
    isEmployee: login.groups.includes('employees'),
    courses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCourses: () => dispatch(getCourses()),
    enrol: id => dispatch(enrolCourse(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCoursesPage);
