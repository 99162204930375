import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormGroup, Alert } from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { confirmRegistrationFormHandler } from '../../../store/register/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const RegisterConfirmForm = ({
  handleSubmit,
  invalid,
  error,
  submitting,
  initialize,
  email,
  code,
  intl,
}) => {
  useEffect(() => {
    initialize({
      email,
      code,
    });

    // If the form initially renders with a value for the email and code fields
    // we can automatically submit to improve the user experience. This should
    // be the case when a user clicks the "confirm" link from within the email
    // sent on sign-up.
    //
    // The submission has to happen on the next tick because the prior call to
    // initialise the form with values does async work.
    if (email && code) {
      setImmediate(handleSubmit(confirmRegistrationFormHandler));
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(confirmRegistrationFormHandler)}>
      {error && <Alert color="danger">{error.message}</Alert>}
      <FormGroup>
        <Field
          component={FormInput}
          name="email"
          id="email"
          type="text"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'RegisterConfirm.Form.email',
            defaultMessage: 'Email address',
          })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormInput}
          name="code"
          id="code"
          type="text"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'RegisterConfirm.Form.code',
            defaultMessage: 'Verification code',
          })}
          required
        />
      </FormGroup>
      <Button
        type="submit"
        size="lg"
        disabled={invalid || submitting}
        block
        color="primary"
      >
        <FormattedMessage id="RegisterConfirm.Form.Send" defaultMessage="Submit" />
      </Button>
    </form>
  );
};

RegisterConfirmForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'RegisterConfirm.Form',
  onSubmit: confirmRegistrationFormHandler,
})(RegisterConfirmForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withReduxForm);

export default withIntl;
