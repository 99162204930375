import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

export const CHECK_SESSION = 'LOGIN/CHECK_SESSION';

export const login = createRoutine('LOGIN');
export const logout = createRoutine('LOGOUT');
export const confirmPassword = createRoutine('CONFIRMPASSWORD');
export const loginFormHandler = bindRoutineToReduxForm(login);
export const confirmPasswordFormHandler = bindRoutineToReduxForm(confirmPassword);
export const checkSession = () => ({ type: CHECK_SESSION });
export const resendConfirmation = createRoutine('RESEND_CONFIRMATION');
