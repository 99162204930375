import { registerOrganisation, registerEmployee, registerManager } from './actions';

export default (state = {}, action) => {
  switch (action.type) {
    case registerOrganisation.SUCCESS:
    case registerEmployee.SUCCESS:
    case registerManager.SUCCESS:
      return state;
    case registerOrganisation.FAILURE:
    case registerEmployee.FAILURE:
    case registerManager.FAILURE:
      return state;
    default:
      return state;
  }
};
