import { login, logout, resendConfirmation } from './actions';

export const initialState = {
  authenticated: false,
  groups: null,
  logout: {},
  resendConfirmation: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case login.SUCCESS:
      return Object.assign({}, state, {
        authenticated: true,
        groups: action.payload.groups,
      });
    case logout.SUCCESS:
      return Object.assign({}, state, {
        authenticated: false,
        groups: null,
      });
    case logout.FAILURE:
      return Object.assign({}, state, {
        logout: { error: true },
      });
    case resendConfirmation.SUCCESS:
      return Object.assign({}, state, {
        resendConfirmation: {
          success: true,
        },
      });
    default:
      return state;
  }
};
