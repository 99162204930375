import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ConfirmPasswordForm from './form';

const ConfirmPasswordPage = ({ location }) => {
  let loginRes;
  let email;

  if (location.state) {
    loginRes = location.state.loginRes; // eslint-disable-line prefer-destructuring
    email = location.state.email; // eslint-disable-line prefer-destructuring
  }

  return (
    <div className="page-confirm-password">
      <h4 className="mb-4">
        <FormattedMessage id="ConfirmPassword.Title" defaultMessage="Create your new password" />
      </h4>
      <FormattedMessage
        id="ConfirmPassword.Advice"
        tagName="p"
        defaultMessage="Please enter a new password."
      />
      <ConfirmPasswordForm loginRes={loginRes} email={email} />
    </div>
  );
};

export default connect()(ConfirmPasswordPage);

ConfirmPasswordPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      loginRes: PropTypes.shape({}),
      email: PropTypes.string,
    }),
  }).isRequired,
};
