/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Row, Col, Progress,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ToggleButtonGroup from '../toggle-button-group';

const StampTable = ({
  stamps,
}) => {
  const [selectedDashboardTab, changeSelectedDashboardTab] = useState(0);

  // Calculate the values for the time bars as a percentage of the hours worked
  // by the employee with the most hours.
  const totalTimeArray = Object
    .keys(stamps)
    .map(employee => stamps[employee].totalTime);
  const mostTime = Math.max(...totalTimeArray);
  const timeBarValues = totalTimeArray
    .map(time => Math.round((time / mostTime) * 100));

  function displaySkills(skills) {
    return (
      <div className="tag-group">
        {skills.map(skill => (
          <div key={skill} className="tag-item">{skill}</div>
        ))}
      </div>
    );
  }

  return (
    <div className="page-dashboard-manager">
      <Row>
        <Col>
          <ToggleButtonGroup
            buttonText={[
              <FormattedMessage id="Dashboard.Manager.ToggleSkills" defaultMessage="Skills" />,
              <FormattedMessage id="Dashboard.Manager.ToggleHours" defaultMessage="Hours" />,
            ]}
            selectedIndex={selectedDashboardTab}
            onChange={changeSelectedDashboardTab}
            block
          />
        </Col>
      </Row>
      <Card style={{ display: selectedDashboardTab === 0 ? 'block' : 'none' }}>
        <CardBody>
          {Object.keys(stamps).map((employee) => {
            const data = stamps[employee];
            const id = data.employee.phoneNumber || data.employee.emailAddress;

            return (
              <div key={employee} className="skills-summary">
                <p className="stamp-phonenumber">{id}</p>
                {displaySkills(data.skills)}
              </div>
            );
          })}
        </CardBody>
      </Card>
      <Card style={{ display: selectedDashboardTab === 1 ? 'block' : 'none' }}>
        <CardBody>
          {Object.keys(stamps).map((employee, i) => {
            const data = stamps[employee];
            const id = data.employee.phoneNumber || data.employee.emailAddress;
            const hours = Math.floor(data.totalTime / 60);
            let minutes = data.totalTime % 60;

            if (minutes < 10) {
              minutes = `0${minutes}`;
            }

            return (
              <div key={employee}>
                <span className="stamp-phonenumber">{id}</span>
                <Progress value={timeBarValues[i]}>
                  {hours}:{minutes}
                </Progress>
              </div>
            );
          })}
        </CardBody>
      </Card>
    </div>
  );
};

StampTable.propTypes = {
  stamps: PropTypes.objectOf(PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    totalTime: PropTypes.number,
  })),
};

StampTable.defaultProps = {
  stamps: {},
};


export default StampTable;
