import {
  createStamp,
  verifyStamp,
  disputeStamp,
  getStamps,
} from '../stamps/actions';
import { registerOrganisation, registerEmployee, confirmRegistration } from '../register/actions';
import { logout, login } from '../login/actions';
import { forgotPassword, resetPassword } from '../forgot-password/actions';
import { enrolCourse } from '../courses/actions';
import { changeLocale } from '../i18n/actions';

export default (state = false, action) => {
  switch (action.type) {
    case confirmRegistration.REQUEST:
    case forgotPassword.REQUEST:
    case resetPassword.REQUEST:
    case createStamp.REQUEST:
    case verifyStamp.REQUEST:
    case disputeStamp.REQUEST:
    case getStamps.REQUEST:
    case registerOrganisation.REQUEST:
    case registerEmployee.REQUEST:
    case changeLocale.REQUEST:
    case logout.REQUEST:
    case login.REQUEST:
    case enrolCourse.REQUEST:
      return true;
    case confirmRegistration.FULFILL:
    case forgotPassword.FULFILL:
    case resetPassword.FULFILL:
    case createStamp.FULFILL:
    case verifyStamp.FULFILL:
    case disputeStamp.FULFILL:
    case getStamps.FULFILL:
    case registerOrganisation.FULFILL:
    case registerEmployee.FULFILL:
    case changeLocale.FULFILL:
    case logout.FULFILL:
    case login.FULFILL:
    case enrolCourse.FULFILL:
      return false;
    default:
      return state;
  }
};
