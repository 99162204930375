import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Auth, API } from 'aws-amplify';
import { changeLocale } from './actions';

export function* handleChangeLocale(action) {
  let user;
  let token;

  yield put(changeLocale.request());

  try {
    user = yield apply(Auth, Auth.currentAuthenticatedUser);
    token = user.signInUserSession.idToken.jwtToken;
  } catch (err) {
    // If an error occurs it is likely to mean that the user is not currently
    // signed in. We can change the locale anyway so that they get the correct
    // UI and get the correct default locale against their profile when they
    // sign up.
    yield put(changeLocale.success(action.payload));
  }

  try {
    yield apply(API, API.put, [
      'tendo',
      `/users/${user.attributes['custom:userId']}`,
      {
        headers: {
          Authorization: token,
        },
        body: {
          locale: action.payload,
        },
      },
    ]);

    yield put(changeLocale.success(action.payload));
  } catch (err) {
    yield put(changeLocale.failure(err));
  } finally {
    yield put(changeLocale.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(changeLocale.TRIGGER, handleChangeLocale),
  ]);
}
