import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StampStatusIcon({ status }) {
  switch (status) {
    case 'sent':
      return (
        <span>
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon
              icon="circle"
              className="stamp-icon__sent"
            />
            <FontAwesomeIcon
              icon="sync"
              inverse
              size="xs"
              flip="horizontal"
            />
          </span>
          Pending
        </span>
      );
    case 'verified':
      return (
        <span>
          <FontAwesomeIcon icon="check-circle" className="stamp-icon__verified" /> Verified
        </span>
      );
    case 'rejected':
      return (
        <span>
          <FontAwesomeIcon icon="exclamation-circle" className="stamp-icon__rejected" /> Rejected
        </span>
      );
    default:
      return null;
  }
}

StampStatusIcon.propTypes = {
  status: PropTypes.oneOf([
    'sent',
    'verified',
    'rejected',
  ]).isRequired,
};
