export default {
  Aws: {
    REGION: 'eu-west-2',
    Cognito: {
      USER_POOL_ID: 'eu-west-2_EYCWapuqs',
      IDENTITY_POOL_ID: 'eu-west-2:74d41951-0965-4720-9c48-7bbfc9e2a8c9',
      USER_POOL_CLIENT_ID: '4mjptp5brne886kkljhqljgm1n',
      Cookie: {
        DOMAIN: '.tendo.id',
        SECURE: true,
      },
    },
    APIGateway: {
      URL: 'https://6kimt2udcg.execute-api.eu-west-2.amazonaws.com/production',
    },
  },
  GoogleAnalytics: {
    TRACKING_CODE: 'UA-146128192-1',
  },
};
