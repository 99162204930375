import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GA from 'react-ga';

// Higher-order component to track page views in Google Analytics when a newly
// matched route is rendered.
export default (Component, options = {}) => {
  const trackPage = (page) => {
    GA.set({
      page,
      ...options,
    });
    GA.pageview(page);
  };

  const WithTracker = (props) => {
    const { location: { pathname } } = props;

    useEffect(() => {
      trackPage(pathname);
    }, [pathname]);

    return <Component {...props} />;
  };

  WithTracker.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  return WithTracker;
};
