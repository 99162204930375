import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ManagerRegisterForm from './form-manager';
import ManagerTable from './table-manager';

const AddManagersPage = ({ location }) => {
  let action;
  let email;

  if (location.state) {
    action = location.state.action; // eslint-disable-line prefer-destructuring
    email = location.state.email; // eslint-disable-line prefer-destructuring
  }

  return (
    <div className="page-add-manager container-sm">
      <h4 className="mb-4">
        <FormattedMessage id="Manager.Title" defaultMessage="Managers Overview" />
      </h4>
      <ManagerRegisterForm action={action} email={email} />
      <ManagerTable />
    </div>
  );
};

export default connect()(AddManagersPage);

AddManagersPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      action: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};
