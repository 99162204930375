import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ConfirmRegistrationForm from './form';

const RegisterConfirmPage = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const code = params.get('code');

  return (
    <div className="page-register-confirm">
      <h2>
        <FormattedMessage
          id="RegisterConfirm.Title"
          defaultMessage="Confirm your email address"
        />
      </h2>
      <p>
        <FormattedMessage
          id="RegisterConfirm.Advice"
          defaultMessage="We have sent an email to the address you entered on the previous page. Please check your inbox and click on the link in the email, or enter the code contained within the email here."
        />
      </p>
      <ConfirmRegistrationForm email={email} code={code} />
    </div>
  );
};

RegisterConfirmPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect()(RegisterConfirmPage);
