import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import withTracker from './with-tracker';

// Wrapper component for react-router routes to allow us to provide custom props
// to the actual route components via a render prop.
const CustomRoute = React.memo(({
  component: Component,
  requiresAuth,
  authenticated,
  tracking,
  ...rest
}) => {
  const render = (props) => {
    if (requiresAuth && !authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }, // eslint-disable-line react/prop-types
          }}
        />
      );
    }

    const Tracked = withTracker(Component, tracking);

    return <Tracked {...props} />;
  };

  return (
    <Route render={render} {...rest} />
  );
});

CustomRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  requiresAuth: PropTypes.bool,
  authenticated: PropTypes.bool,
  tracking: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

CustomRoute.defaultProps = {
  requiresAuth: false,
  authenticated: false,
  tracking: {},
};

export default CustomRoute;
