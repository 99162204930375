import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const CustomNavbar = ({
  locale,
  changeLocale,
  authenticated,
  isEmployee,
  isOrganisation,
}) => {
  const [navbarOpen, changeNavbarOpen] = useState(false);
  const [langsOpen, changeLangsOpen] = useState(false);
  // We only show a full navbar once logged into the dashboard. Prior to that
  // we show the logo and a language selection menu.
  if (!authenticated) {
    const locales = {
      en: (
        <React.Fragment>
          <img src="/en.png" alt="UK flag" />
          <FormattedMessage id="Nav.Language.English" defaultMessage="English" />
        </React.Fragment>
      ),
      es: (
        <React.Fragment>
          <img src="/es.png" alt="Spain flag" />
          <FormattedMessage id="Nav.Language.Spanish" defaultMessage="Español" />
        </React.Fragment>
      ),
      de: (
        <React.Fragment>
          <img src="/de.png" alt="Germany flag" />
          <FormattedMessage id="Nav.Language.German" defaultMessage="Deutsche" />
        </React.Fragment>
      ),
      ro: (
        <React.Fragment>
          <img src="/ro.png" alt="Romanian flag" />
          <FormattedMessage id="Nav.Language.Romanian" defaultMessage="Română" />
        </React.Fragment>
      ),
    };
    const selectedLocale = locales[locale] || locales.en;

    return (
      <div className="container-xxs container text-center px-3 px-sm-5">
        <Dropdown
          className="dropdown-lang"
          isOpen={langsOpen}
          toggle={() => changeLangsOpen(!langsOpen)}
        >
          <DropdownToggle className="btn-sm btn-light" caret>
            {selectedLocale}
          </DropdownToggle>
          <DropdownMenu>
            {Object.entries(locales).map(([id, fragment]) => {
              if (locale === id) {
                return null;
              }

              return (
                <DropdownItem key={id} onClick={() => changeLocale(id)}>
                  {fragment}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <img src="/tendo.svg" alt="Tendo" />
      </div>
    );
  }

  return (
    <Navbar expand="md" dark>
      <NavbarToggler onClick={() => changeNavbarOpen(!navbarOpen)} />
      <NavbarBrand tag={Link} to="/">
        <img src="/tendo-white.svg" alt="tendo logo" />
      </NavbarBrand>
      <Link to="/stamps/new" className="btn btn-info btn-cta ml-auto">
        <span className="d-none d-md-inline mr-2">
          <FormattedMessage id="Nav.CreateStamp" defaultMessage="Create stamp" />
        </span>
        <span className="plus-sign" />
      </Link>
      <Collapse isOpen={navbarOpen} className="mr-0 mr-md-3" navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to="/">
              <FormattedMessage id="Nav.Dashboard" defaultMessage="Dashboard" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/stamps">
              <FormattedMessage id="Nav.ViewStamps" defaultMessage="History" />
            </NavLink>
          </NavItem>
          {isOrganisation && (
            <NavItem>
              <NavLink tag={Link} to="/managers">
                <FormattedMessage id="Nav.Managers" defaultMessage="Managers" />
              </NavLink>
            </NavItem>
          )}
          {isEmployee && (
            <NavItem>
              <NavLink tag={Link} to="/courses">
                <FormattedMessage id="Nav.ViewCourses" defaultMessage="Courses" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="dropdown-toggle-alt" nav>
              <FormattedMessage id="Nav.Settings" defaultMessage="Settings" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={Link} to="/logout">
                <FormattedMessage id="Nav.SignOut" defaultMessage="Sign out" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

CustomNavbar.propTypes = {
  changeLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  authenticated: PropTypes.bool,
  isEmployee: PropTypes.bool,
  isOrganisation: PropTypes.bool,
};

CustomNavbar.defaultProps = {
  authenticated: false,
  isEmployee: null,
  isOrganisation: null,
};

export default CustomNavbar;
