import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import { Auth } from 'aws-amplify';
import { defineMessages } from 'react-intl';
import {
  registerOrganisation,
  registerManager,
  registerEmployee,
  verifyRegistration,
  confirmRegistration,
} from './actions';
import { intl } from '../../containers/i18n';
import getUserService from '../../services/user';

const registerMessages = defineMessages({
  // This exception appears to be thrown by Cognito when certain password
  // requirements are not met. It's not immediately clear when this one is
  // thrown compared to the more-appropriately-named InvalidPasswordException.
  InvalidParameterException: {
    id: 'Register.Error.InvalidParameter',
    defaultMessage: 'Your password must be at least 8 characters long. It must include at least one lowercase letter, uppercase letter, number and symbol.',
  },
  InvalidPasswordException: {
    id: 'Register.Error.InvalidPassword',
    defaultMessage: 'Your password must be at least 8 characters long. It must include at least one lowercase letter, uppercase letter, number and symbol.',
  },
  UsernameExistsException: {
    id: 'Register.Error.UsernameExists',
    defaultMessage: 'An account with this email address already exists.',
  },
  generic: {
    id: 'Register.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const registerConfirmMessages = defineMessages({
  ExpiredCodeException: {
    id: 'ResetPassword.Error.CodeExpired',
    defaultMessage: 'The verification code has expired. We\'ve sent a new one which will remain active for 24 hours.',
  },
  CodeMismatchException: {
    id: 'ResetPassword.Error.CodeMismatch',
    defaultMessage: 'The verification code you provided was incorrect.',
  },
  generic: {
    id: 'ResetPassword.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

// Handle "registerOrganisation" and "registerEmployee" trigger actions, dispatched
// by redux-form. Organisations need a business name which gets stored in Cognito as
// a custom attribute. Employees need a "normal" name which can be stored in the
// "name" property provided natively by Cognito.
export function* handleRegister(routine, action) {
  try {
    yield put(routine.request());

    if (routine === registerManager) {
      const data = {
        email: action.payload.values.email,
        name: action.payload.values.name,
        locale: action.payload.props.intl.locale,
      };
      const userService = getUserService();
      const signUpResult = yield apply(userService, userService.post, [data]);

      yield put(routine.success(signUpResult));
      yield put(push('/managers', {
        action: 'REGISTER_SUCCESSFUL',
        email: action.payload.values.email,
      }));
    } else {
      const data = {
        username: action.payload.values.email,
        password: action.payload.values.password,
        attributes: {
          'custom:locale': action.payload.props.intl.locale,
        },
      };

      if (routine === registerOrganisation) {
        data.attributes['custom:business'] = action.payload.values.businessName;
      } else if (routine === registerEmployee) {
        data.attributes.name = action.payload.values.name;
      }

      const signUpResult = yield apply(Auth, Auth.signUp, [data]);

      yield put(routine.success(signUpResult));
      yield put(push('/register/confirm'));
    }
  } catch (err) {
    const message = registerMessages[err.code] || registerMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(routine.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(routine.fulfill());
  }
}

// Handle "confirmRegistration" trigger actions, dispatched by redux-form. There
// is no need to differentiate between an organisation and employees here because both
// confirm their registrations in the same way (via verification code in an
// email).
export function* handleRegisterConfirm(action) {
  try {
    yield put(confirmRegistration.request());

    const verificationResult = yield apply(Auth, Auth.confirmSignUp, [
      action.payload.values.email,
      action.payload.values.code,
    ]);

    yield put(confirmRegistration.success(verificationResult));
    yield put(push('/login', {
      action: 'CONFIRM_EMAIL',
      email: action.payload.values.email,
    }));
  } catch (err) {
    const message = registerConfirmMessages[err.code] || registerConfirmMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(confirmRegistration.failure(new SubmissionError({ _error: error })));

    if (err.code === 'ExpiredCodeException') {
      yield put(verifyRegistration({
        values: {
          email: action.payload.values.email,
        },
      }));
    }
  } finally {
    yield put(confirmRegistration.fulfill());
  }
}

// Handle "verifyRegistration" trigger actions. These are dispatched by the
// register confirm handler in cases where a previous confirmation code has
// expired.
export function* handleRegisterVerify(action) {
  try {
    yield put(verifyRegistration.request());

    const verificationResult = yield apply(Auth, Auth.resendSignUp, [
      action.payload.values.email,
    ]);

    yield put(verifyRegistration.success(verificationResult));
  } catch (err) {
    yield put(verifyRegistration.failure(err));
  } finally {
    yield put(verifyRegistration.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(registerOrganisation.TRIGGER, handleRegister.bind(null, registerOrganisation)),
    takeLatest(registerManager.TRIGGER, handleRegister.bind(null, registerManager)),
    takeLatest(registerEmployee.TRIGGER, handleRegister.bind(null, registerEmployee)),
    takeLatest(confirmRegistration.TRIGGER, handleRegisterConfirm),
    takeLatest(verifyRegistration.TRIGGER, handleRegisterVerify),
  ]);
}
