import React from 'react';
import classNames from 'classnames';
import { fieldPropTypes } from 'redux-form';
import { Input, FormFeedback } from 'reactstrap';

// Wrapper for reactstrap input components that implements the redux-form field
// interface.
//
// Reference: https://redux-form.com/7.4.2/docs/api/field.md/
export default class FormInput extends React.PureComponent {
  static propTypes = {
    ...fieldPropTypes,
  };

  render() {
    const {
      meta: {
        touched,
        error,
      },
      input,
      className,
      ...rest
    } = this.props;

    const classes = classNames(className, {
      'is-valid': touched && !error,
      'is-invalid': touched && error,
    });

    return (
      <React.Fragment>
        <Input className={classes} {...input} {...rest} />
        {touched && error && <FormFeedback className="form-feedback">{error}</FormFeedback>}
      </React.Fragment>
    );
  }
}
