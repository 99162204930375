import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormGroup, Alert } from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { resetPasswordFormHandler } from '../../../store/forgot-password/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const ResetPasswordForm = ({
  handleSubmit,
  pristine,
  error,
  invalid,
  submitting,
  initialize,
  email,
  intl,
}) => {
  useEffect(() => {
    initialize({
      email,
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(resetPasswordFormHandler)}>
      {error && <Alert color="danger">{error.message}</Alert>}
      <FormGroup>
        <Field
          component={FormInput}
          name="email"
          id="email"
          type="text"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'ResetPassword.Form.Email',
            defaultMessage: 'Email',
          })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormInput}
          name="code"
          id="code"
          type="text"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'ResetPassword.Form.Code',
            defaultMessage: 'Verification Code',
          })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormInput}
          name="password"
          id="password"
          type="password"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'ResetPassword.Form.Password',
            defaultMessage: 'New Password',
          })}
          required
        />
      </FormGroup>
      <Button
        type="submit"
        size="lg"
        disabled={invalid || pristine || submitting}
        block
        color="primary"
      >
        <FormattedMessage id="ResetPassword.Form.Send" defaultMessage="Submit" />
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'ResetPassword.Form',
})(ResetPasswordForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withReduxForm);

export default withIntl;
