import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import {
  Button,
  FormGroup,
  Label,
  Col,
  Row,
  Alert,
} from 'reactstrap';
import { reduxForm, propTypes as reduxFormPropTypes, Field } from 'redux-form';
import { createStampFormHandler } from '../../../../store/stamps/actions';
import FormInput from '../../../../components/form-input';
import { requiredField } from '../../../../utils/validation';
import TagFormInput from '../../../../components/tag-form-input';

// Define i18n messages. This is a hook for the Babel plugin which extracts all
// of the definitions out into JSON at build-time.
const messages = defineMessages({
  dateOrderError: {
    id: 'Stamps.Create.Form.Error.DateOrder',
    defaultMessage: 'The end date cannot be earlier than the start date.',
  },
  futureEndDateError: {
    id: 'Stamps.Create.Form.Error.EndDate',
    defaultMessage: 'You can not send Stamps for the future. Please adjust your Time Period and try again.',
  },
  minHoursError: {
    id: 'Stamps.Create.Form.Error.MinHours',
    defaultMessage: 'Hours must be greater than zero.',
  },
  minMinutesError: {
    id: 'Stamps.Create.Form.Error.MinMinutes',
    defaultMessage: 'Minutes must not be less than zero.',
  },
  maxHoursError: {
    id: 'Stamps.Create.Form.Error.MaxHours',
    defaultMessage: 'Too many hours.',
  },
  timePeriodToday: {
    id: 'Stamps.Create.Form.TimePeriodRecent.Today',
    defaultMessage: 'Today',
  },
  timePeriodYesterday: {
    id: 'Stamps.Create.Form.TimePeriodRecent.Yesterday',
    defaultMessage: 'Yesterday',
  },
  timePeriodLastWeek: {
    id: 'Stamps.Create.Form.TimePeriodRecent.LastWeek',
    defaultMessage: 'Last Week',
  },
  timePeriodLastMonth: {
    id: 'Stamps.Create.Form.TimePeriodRecent.LastMonth',
    defaultMessage: 'Last Month',
  },
});

// The start date cannot be after the end date.
const validateTimePeriod = (timePeriodEnd, { timePeriodStart }, { intl }) => {
  if (timePeriodStart && timePeriodEnd) {
    const startDate = new Date(timePeriodStart);
    const endDate = new Date(timePeriodEnd);
    if (startDate > endDate) {
      return intl.formatMessage(messages.dateOrderError);
    }
    if (endDate > new Date()) {
      return intl.formatMessage(messages.futureEndDateError);
    }
  }
};

// Hours must be greater than zero and no greater than the number of hours in
// the time period.
function validateTimeInPeriod({
  hours = '0',
  minutes = '0',
  timePeriodStart,
  timePeriodEnd,
}) {
  if (timePeriodStart && timePeriodEnd) {
    const startDate = new Date(timePeriodStart);
    const endDate = new Date(timePeriodEnd);

    if (startDate <= endDate) {
      const totalMinutes = (hours * 60) + parseInt(minutes, 10);
      const maxMinutes = (timePeriodStart === timePeriodEnd)
        ? 1440
        : (endDate - startDate) / 1000 / 60;


      if (totalMinutes < maxMinutes) {
        return true;
      }
    }

    return false;
  }
}

const validateHours = (hours, { minutes, timePeriodStart, timePeriodEnd }, { intl }) => {
  if (hours <= 0 && minutes <= 0) {
    return intl.formatMessage(messages.minHoursError);
  }

  const isValid = validateTimeInPeriod({
    hours,
    minutes,
    timePeriodStart,
    timePeriodEnd,
  });

  if (isValid === false) {
    return intl.formatMessage(messages.maxHoursError);
  }
};

const validateMinutes = (minutes, { hours, timePeriodStart, timePeriodEnd }, { intl }) => {
  if (minutes < 0) {
    return intl.formatMessage(messages.minMinutesError);
  }

  const isValid = validateTimeInPeriod({
    hours,
    minutes,
    timePeriodStart,
    timePeriodEnd,
  });

  if (isValid === false) {
    return intl.formatMessage(messages.maxHoursError);
  }
};

const CreateStampForm = ({
  user,
  course,
  module,
  handleSubmit,
  pristine,
  invalid,
  error,
  submitting,
}) => {
  const isManager = user.groups.includes('employers');
  const isOrganisation = user.groups.includes('organisations');

  // If the stamp is linked to a course module the user only needs to provide
  // the date range on which they completed the module. The hours, skills and
  // recipient data can be determined server-side from the course and module
  // identifiers.

  // Both employees, managers and organisations can send stamps to email addresses.
  // Organisations and managers can enter multiple addresses whereas employees can only enter one.
  let fieldsetEmailAddresses;

  if (isOrganisation || isManager) {
    fieldsetEmailAddresses = (
      <FormGroup tag="fieldset">
        <Row className="row-sm" form>
          <Col>
            <Label for="emailAddresses">
              <FormattedMessage
                id="Stamps.Create.Form.EmailAddressesManager"
                defaultMessage="Email addresses"
              />
            </Label>
            <Field
              component={TagFormInput}
              name="emailAddresses"
              id="emailAddresses"
              type="text"
              bsSize="lg"
              required
            />
          </Col>
        </Row>
        <p className="form-text text-muted small">
          <FormattedMessage
            id="Stamps.Create.Form.EmailAddressesManager.Help"
            defaultMessage="Enter the email addresses of the recepients of this stamp. You can enter multiple email addresses separated by commas."
          />
        </p>
      </FormGroup>
    );
  } else if (!course || !module) {
    fieldsetEmailAddresses = (
      <FormGroup tag="fieldset">
        <Row className="row-sm" form>
          <Col>
            <Label for="emailAddresses">
              <FormattedMessage
                id="Stamps.Create.Form.EmailAddressesEmployee"
                defaultMessage="Email address"
              />
            </Label>
            <Field
              component={FormInput}
              name="emailAddresses"
              id="emailAddresses"
              type="text"
              bsSize="lg"
            />
          </Col>
        </Row>
        <p className="form-text text-muted small">
          <FormattedMessage
            id="Stamps.Create.Form.EmailAddressesEmployee.Help"
            defaultMessage="Add your manager's email address to send the Stamp for verification. Your manager will receive an email to verify - don't worry if they haven't got a Tendo account yet."
          />
        </p>
      </FormGroup>
    );
  }

  const fieldsetHours = course && module ? null : (
    <FormGroup tag="fieldset">
      <Row className="row-sm" form>
        <Col xs={6}>
          <Label for="hours">
            <FormattedMessage
              id="Stamps.Create.Form.Hours"
              defaultMessage="Hours"
            />
          </Label>
          <Field
            component={FormInput}
            name="hours"
            id="hours"
            type="number"
            min="0"
            validate={[requiredField, validateHours]}
            bsSize="lg"
            pattern="[0-9]+"
            required
          />
        </Col>
        <Col xs={6}>
          <Label for="minutes">
            <FormattedMessage
              id="Stamps.Create.Form.Minutes"
              defaultMessage="Minutes"
            />
          </Label>
          <Field
            component={FormInput}
            name="minutes"
            id="minutes"
            type="number"
            min="0"
            max="59"
            validate={[validateMinutes]}
            pattern="[0-9]*"
            bsSize="lg"
          />
        </Col>
      </Row>
      <p className="form-text text-muted small">
        <FormattedMessage
          id="Stamps.Create.Form.Hours.Help"
          defaultMessage="Enter the amount of time you have worked, in hours and optionally minutes, between the selected dates."
        />
      </p>
    </FormGroup>
  );

  const fieldsetSkills = course && module ? null : (
    <FormGroup tag="fieldset">
      <Row className="row-sm" form>
        <Col>
          <Label for="skills">
            <FormattedMessage id="Stamps.Create.Form.Skill" defaultMessage="Skills" />
          </Label>
          <Field
            component={TagFormInput}
            name="skills"
            id="skills"
            type="text"
          />
        </Col>
      </Row>
      <p className="form-text text-muted small">
        <FormattedMessage
          id="Stamps.Create.Form.Skills.Help"
          defaultMessage="Add multiple skills by separating them with a comma."
        />
      </p>
    </FormGroup>
  );

  return (
    <form onSubmit={handleSubmit(createStampFormHandler)}>
      {error && <Alert color="danger">{error.message}</Alert>}
      <FormGroup tag="fieldset">
        <Row className="row-sm" form>
          <Col xs={6}>
            <Label for="timePeriodStart">
              <FormattedMessage
                id="Stamps.Create.Form.StartDate"
                defaultMessage="Date from"
              />
            </Label>
            <Field
              component={FormInput}
              name="timePeriodStart"
              id="timePeriodStart"
              type="date"
              bsSize="lg"
              required
            />
          </Col>
          <Col xs={6}>
            <Label for="timePeriodEnd">
              <FormattedMessage
                id="Stamps.Create.Form.EndDate"
                defaultMessage="Date to"
              />
            </Label>
            <Field
              component={FormInput}
              name="timePeriodEnd"
              id="timePeriodEnd"
              type="date"
              validate={[validateTimePeriod]}
              bsSize="lg"
              required
            />
          </Col>
        </Row>
        <p className="form-text text-muted small">
          <FormattedMessage
            id="Stamps.Create.Form.Dates.Help"
            defaultMessage="Select the start and end dates, inclusive, between which you have worked."
          />
        </p>
      </FormGroup>
      {fieldsetHours}
      {fieldsetSkills}
      {fieldsetEmailAddresses}
      <Button
        type="submit"
        size="lg"
        disabled={invalid || pristine || submitting}
        block
        color="primary"
      >
        <FormattedMessage id="Stamps.Create.Form.Send" defaultMessage="Send" />
      </Button>
    </form>
  );
};

CreateStampForm.propTypes = {
  ...reduxFormPropTypes,
};

export default injectIntl(
  reduxForm({
    form: 'Stamps.Create.Form',
  })(CreateStampForm),
);
