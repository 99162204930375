import BaseService from '../base';

class UserService extends BaseService {
  async get() {
    let managers = [];

    managers = await super.get('/users/managers');

    return {
      success: true,
      ...managers,
    };
  }

  async post(data) {
    const {
      name,
      email,
      locale,
    } = data;

    await super.post('/users/managers', {
      email,
      locale,
      name,
    });

    return {
      success: true,
    };
  }

  async confirmManager() {
    await super.post('/users/confirm-manager');

    return {
      success: true,
    };
  }
}

let instance;

export default (...args) => {
  if (!instance) {
    instance = new UserService(...args);
  }

  return instance;
};
