import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ManagerDashboard from './manager';
import EmployeeDashboard from './employee';
import OrganisationDashboard from './organisation';

const Dashboard = ({
  isEmployee,
  isOrganisation,
  location,
}) => {
  let component = <ManagerDashboard />;
  if (isEmployee) {
    component = <EmployeeDashboard />;
  } else if (isOrganisation) {
    component = <OrganisationDashboard />;
  }

  // Render an alert message if necessary.
  let message;
  let action;

  if (location.state) {
    action = location.state.action; // eslint-disable-line prefer-destructuring
  }

  switch (action) {
    case 'STAMP_CREATE':
      message = (
        <Alert color="success">
          <FormattedMessage
            id="Form.Stamp.Successful"
            defaultMessage="Stamp created successfully."
          />
        </Alert>
      );
      break;
    default:
      message = null;
  }

  return (
    <div className="page-dashboard container-sm">
      {message}
      {component}
    </div>
  );
};

Dashboard.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  isOrganisation: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      action: PropTypes.string,
    }),
  }).isRequired,
};

function mapStateToProps({ login }) {
  return {
    isEmployee: login.groups.includes('employees'),
    isOrganisation: login.groups.includes('organisations'),
  };
}

export default connect(mapStateToProps)(Dashboard);
