import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { disputeCourseEnrolment } from '../../../store/courses/actions';

const DisputeCourseEnrolmentPage = ({
  success,
  error,
  dispute,
  location: { search },
  match: { params: { courseId } },
}) => {
  useEffect(() => {
    const { id } = qs.parse(search);

    dispute(courseId, id);
  }, [dispute, courseId, search]);

  return (
    <div className="page-course-enrolment-verify">
      {success && <Alert color="success">User has been rejected for this enrolment.</Alert>}
      {error && <Alert color="danger">{error.message}</Alert>}
    </div>
  );
};

DisputeCourseEnrolmentPage.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  dispute: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      courseId: PropTypes.string,
    }),
  }).isRequired,
};

DisputeCourseEnrolmentPage.defaultProps = {
  success: null,
  error: null,
};

function mapStateToProps({ courses: { dispute: { success, error } } }) {
  return { success, error };
}

function mapDispatchToProps(dispatch) {
  return {
    dispute: (courseId, userId) => dispatch(disputeCourseEnrolment({ courseId, userId })),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(DisputeCourseEnrolmentPage);
