import {
  all,
  apply,
  takeLatest,
} from 'redux-saga/effects';
import GA from 'react-ga';
import {
  registerOrganisation,
  registerEmployee,
} from '../register/actions';

export function* analyticsRegisterSuccess() {
  yield apply(GA, GA.event, [
    {
      category: 'User',
      action: 'Completed registration',
    },
  ]);
}

export function* analyticsRegisterFailure() {
  yield apply(GA, GA.event, [
    {
      category: 'User',
      action: 'Failed registration',
    },
  ]);
}

export default function* watch() {
  yield all([
    takeLatest(registerOrganisation.SUCCESS, analyticsRegisterSuccess),
    takeLatest(registerEmployee.SUCCESS, analyticsRegisterSuccess),
    takeLatest(registerOrganisation.FAILURE, analyticsRegisterFailure),
    takeLatest(registerEmployee.FAILURE, analyticsRegisterFailure),
  ]);
}
