import {
  getCourse,
  getCourses,
  enrolCourse,
  verifyCourseEnrolment,
  disputeCourseEnrolment,
} from './actions';

export default (state = { courses: [], verify: {}, dispute: {} }, action) => {
  switch (action.type) {
    case getCourses.SUCCESS:
      return Object.assign({}, state, { courses: action.payload.courses });
    case getCourse.SUCCESS: {
      const newCourses = JSON.parse(JSON.stringify(state.courses));
      let replaced = false;

      // Replace the relevant course with the response. This should be a full
      // course object including module data.
      for (let i = 0; i < newCourses.length; i++) {
        if (newCourses[i].id === action.payload.course.id) {
          const currentData = newCourses[i];
          newCourses[i] = action.payload.course;
          newCourses[i].enrolmentStatus = currentData.enrolmentStatus;
          replaced = true;
          break;
        }
      }

      if (!replaced) {
        newCourses.push(action.payload.course);
      }

      return Object.assign({}, state, { courses: newCourses });
    }
    case enrolCourse.SUCCESS: {
      const newCourses = JSON.parse(JSON.stringify(state.courses));
      const enroledCourse = newCourses.find(course => course.id === action.payload.courseId);

      enroledCourse.enrolmentStatus = 'sent';

      return Object.assign({}, state, { courses: newCourses });
    }
    case verifyCourseEnrolment.SUCCESS:
      return Object.assign({}, state, {
        verify: {
          success: true,
        },
      });
    case verifyCourseEnrolment.FAILURE:
      return Object.assign({}, state, {
        verify: {
          error: action.payload,
        },
      });
    case disputeCourseEnrolment.SUCCESS:
      return Object.assign({}, state, {
        dispute: {
          success: true,
        },
      });
    case disputeCourseEnrolment.FAILURE:
      return Object.assign({}, state, {
        dispute: {
          error: action.payload,
        },
      });
    default:
      return state;
  }
};
