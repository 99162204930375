import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormGroup, Alert } from 'reactstrap';
import {
  reduxForm,
  Field,
  formValueSelector,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { loginFormHandler, resendConfirmation } from '../../../store/login/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const LoginForm = ({
  handleSubmit,
  pristine,
  error,
  invalid,
  submitting,
  initialize,
  email,
  emailFieldValue,
  intl,
  resendConfirmation, // eslint-disable-line no-shadow
  resendConfirmationSuccess,
}) => {
  useEffect(() => {
    initialize({
      email,
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(loginFormHandler)}>
      {error && !resendConfirmationSuccess && <Alert color="danger">{error.message}</Alert>}
      {error
        && error.code === 'UserNotConfirmedException'
        && !resendConfirmationSuccess
        && (
          <Button
            onClick={() => resendConfirmation(emailFieldValue)}
            type="button"
            className="mb-4"
            block
          >
            Resend confirmation
          </Button>
        )
      }
      {resendConfirmationSuccess && <Alert color="success">Your confirmation email has been resent successfully.</Alert>}
      <FormGroup>
        <Field
          component={FormInput}
          name="email"
          id="email"
          type="text"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'Login.Form.Email',
            defaultMessage: 'Email',
          })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={FormInput}
          name="password"
          id="password"
          type="password"
          bsSize="lg"
          validate={[requiredField]}
          placeholder={intl.formatMessage({
            id: 'Login.Form.Password',
            defaultMessage: 'Password',
          })}
          required
        />
      </FormGroup>
      <Button
        type="submit"
        size="lg"
        disabled={invalid || pristine || submitting}
        color="primary"
        className="my-4"
        block
      >
        <FormattedMessage id="Login.Form.Send" defaultMessage="Log in" />
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'Login.Form',
})(LoginForm);

// Enhance the enhanced form component to provide i18n APIs. These are required
// by validation utilities which receive all of the props passed down to the
// form.
const withIntl = injectIntl(withReduxForm);

// Enhance with Redux to get access to dispatch.
const selector = formValueSelector('Login.Form');

function mapStateToProps(state) {
  return {
    emailFieldValue: selector(state, 'email'),
    resendConfirmationSuccess: state.login.resendConfirmation.success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resendConfirmation: email => dispatch(resendConfirmation(email)),
  };
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)(withIntl);

export default withRedux;
