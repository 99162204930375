import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import { Auth } from 'aws-amplify';
import { defineMessages } from 'react-intl';
import {
  login,
  CHECK_SESSION,
  logout,
  resendConfirmation,
  confirmPassword,
} from './actions';
import { intl } from '../../containers/i18n';
import getUserService from '../../services/user';

const loginMessages = defineMessages({
  NotAuthorizedException: {
    id: 'Login.Error.NotAuthorized',
    defaultMessage: 'The email address or password provided is incorrect.',
  },
  UserNotConfirmedException: {
    id: 'Login.Error.UserNotConfirmed',
    defaultMessage: 'You have not yet confirmed your email address.',
  },
  UserNotFoundException: {
    id: 'Login.Error.UserNotFound',
    defaultMessage: 'The email address or password provided is incorrect.',
  },
  generic: {
    id: 'Login.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const logoutMessages = defineMessages({
  generic: {
    id: 'Logout.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const confirmPasswordMessages = defineMessages({
  generic: {
    id: 'Logout.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

export function* handleLogin(action) {
  try {
    yield put(login.request());
    const { email, password } = action.payload.values;
    const res = yield apply(Auth, Auth.signIn, [
      email,
      password,
    ]);

    // Users who have been given a temporary password will need to set a
    // permanent one via the confirm password page.
    if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(push('/confirm-password', {
        loginRes: res,
        email,
      }));
    } else {
      yield put(login.success({
        groups: res.signInUserSession.accessToken.payload['cognito:groups'],
        locale: res.attributes['custom:locale'],
      }));
      yield put(push(action.payload.props.from));
    }
  } catch (err) {
    const message = loginMessages[err.code] || loginMessages.generic;
    const error = new Error(intl.formatMessage(message));

    error.code = err.code;
    yield put(login.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(login.fulfill());
  }
}

export function* handleCheckSession() {
  try {
    const res = yield apply(Auth, Auth.currentSession);

    yield put(login.success(res));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* handleLogout() {
  try {
    yield put(logout.request());

    const res = yield apply(Auth, Auth.signOut, []);

    yield put(logout.success(res));
  } catch (err) {
    const message = logoutMessages[err.code] || logoutMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(logout.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(logout.fulfill());
  }
}

export function* handleResendConfirmation(action) {
  try {
    const res = yield apply(Auth, Auth.resendSignUp, [
      action.payload,
    ]);

    yield put(resendConfirmation.success(res));
  } catch (err) {
    yield put(resendConfirmation.failure(err));
  } finally {
    yield put(resendConfirmation.fulfill());
  }
}

export function* handleConfirmPassword(action) {
  try {
    yield put(confirmPassword.request());

    const res = yield apply(Auth, Auth.completeNewPassword, [
      action.payload.props.loginRes,
      action.payload.values.password,
    ]);
    const userService = getUserService();

    yield put(confirmPassword.success(res));

    // There is no cogntio trigger post completing a password so we call
    // the confirmManager lambda here.
    yield apply(userService, userService.confirmManager);
    yield put(push('/login', {
      action: 'CONFIRM_PASSWORD',
      email: action.payload.props.email,
    }));
  } catch (err) {
    const message = confirmPasswordMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(confirmPassword.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(confirmPassword.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(login.TRIGGER, handleLogin),
    takeLatest(CHECK_SESSION, handleCheckSession),
    takeLatest(logout.TRIGGER, handleLogout),
    takeLatest(resendConfirmation.TRIGGER, handleResendConfirmation),
    takeLatest(confirmPassword.TRIGGER, handleConfirmPassword),
  ]);
}
