import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { reducer as formReducer } from 'redux-form';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Auth } from 'aws-amplify';
import {
  middleware as createAsyncInitialStateMiddleware,
  outerReducer,
  innerReducer,
} from 'redux-async-initial-state';

// Reducers.
import loginReducers, { initialState } from './login/reducers';
import registerReducers from './register/reducers';
import stampReducers from './stamps/reducers';
import courseReducers from './courses/reducers';
import busyReducer from './busy/reducers';
import managersReducers from './managers/reducers';
import i18nReducer from './i18n/reducers';

// Sagas.
import analyticsSagas from './analytics/sagas';
import loginSagas from './login/sagas';
import registerSagas from './register/sagas';
import forgotPasswordSagas from './forgot-password/sagas';
import stampSagas from './stamps/sagas';
import courseSagas from './courses/sagas';
import managersSagas from './managers/sagas';
import i18nSagas from './i18n/sagas';

// Function to asynchronously load initial state. We use this to get a session
// from Cognito if one exists. If we don't preload the session state the user
// will be redirected to the login page when loading a protected route over HTTP
// (instead of from within the single page app).
async function loadInitialState(getCurrentState) {
  try {
    const session = await Auth.currentSession();
    const { login, i18n, ...rest } = getCurrentState();
    const initialLoginState = {
      ...initialState,
      ...login,
      authenticated: true,
      groups: session.idToken.payload['cognito:groups'],
      userId: session.idToken.payload['custom:userId'],
    };
    const initialI18nState = {
      ...i18n,
      locale: session.idToken.payload.locale,
    };

    return {
      login: {
        ...initialLoginState,
      },
      i18n: {
        ...initialI18nState,
      },
      ...rest,
    };
  } catch (err) {
    return {
      ...getCurrentState(),
      login: {
        ...initialState,
      },
    };
  }
}

// Create history object for navigation. This is exported because the router
// component needs it as a prop on render.
export const history = createBrowserHistory();

// Configure Redux middleware.
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const asyncInitialStateMiddleware = createAsyncInitialStateMiddleware(
  loadInitialState,
);

// Create reducer.
const reducers = outerReducer(combineReducers({
  asyncInitialState: innerReducer,
  router: connectRouter(history),
  i18n: i18nReducer,
  busy: busyReducer,
  login: loginReducers,
  register: registerReducers,
  stamps: stampReducers,
  courses: courseReducers,
  managers: managersReducers,
  form: formReducer,
}));

// Create Redux store.
const store = createStore(reducers, applyMiddleware(
  asyncInitialStateMiddleware,
  routerMiddleware,
  sagaMiddleware,
));

// Configure sagas.
const rootSaga = function* rootSaga() {
  yield all([
    analyticsSagas(),
    loginSagas(),
    registerSagas(),
    forgotPasswordSagas(),
    stampSagas(),
    courseSagas(),
    managersSagas(),
    i18nSagas(),
    routinePromiseWatcherSaga(),
  ]);
};

sagaMiddleware.run(rootSaga);

export default store;
