import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  FormGroup,
  Alert,
} from 'reactstrap';
import {
  reduxForm,
  Field,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { registerManagerFormHandler } from '../../../store/register/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const ManagerRegisterForm = ({
  handleSubmit,
  pristine,
  invalid,
  error,
  submitting,
  intl,
  action,
  email,
}) => {
  let message;

  switch (action) {
    case 'REGISTER_SUCCESSFUL':
      message = (
        <Alert color="success">
          <FormattedMessage
            id="Register.Manager.Alert.Success"
            defaultMessage="Invite sent to {email}"
            values={{
              email,
            }}
          />
        </Alert>
      );
      break;
    default:
      message = null;
  }

  return (
    <>
      <h5 className="mb-4">
        <FormattedMessage id="Manager.Register.Title" defaultMessage="Invite Manager to join Organisation account" />
      </h5>
      <form onSubmit={handleSubmit(registerManagerFormHandler)}>
        {!error && message}
        {error && <Alert color="danger">{error.message}</Alert>}
        <FormGroup>
          <Field
            component={FormInput}
            name="name"
            id="name"
            type="text"
            bsSize="lg"
            validate={[requiredField]}
            placeholder={intl.formatMessage({
              id: 'Register.Form.Employee.Name',
              defaultMessage: 'Full Name',
            })}
            required
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={FormInput}
            name="email"
            id="email"
            type="text"
            bsSize="lg"
            validate={[requiredField]}
            placeholder={intl.formatMessage({
              id: 'Register.Manager.Form.Email',
              defaultMessage: 'Manager Email',
            })}
            required
          />
        </FormGroup>
        <Button
          type="submit"
          size="lg"
          disabled={invalid || pristine || submitting}
          color="primary"
          className="my-4"
          block
        >
          <FormattedMessage id="Register.Manager.Form.Send" defaultMessage="Invite" />
        </Button>
      </form>
    </>
  );
};

ManagerRegisterForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'Register.Form',
})(ManagerRegisterForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withReduxForm);

export default withIntl;
