import {
  createStamp,
  verifyStamp,
  disputeStamp,
  getStamps,
} from './actions';

export default (state = { stamps: [], verify: {}, dispute: {} }, action) => {
  switch (action.type) {
    case createStamp.SUCCESS:
      return state;
    case createStamp.FAILURE:
      return state;
    case verifyStamp.SUCCESS:
      return Object.assign({}, state, {
        verify: {
          success: true,
        },
      });
    case verifyStamp.FAILURE:
      return Object.assign({}, state, {
        verify: {
          error: action.payload,
        },
      });
    case disputeStamp.SUCCESS:
      return Object.assign({}, state, {
        dispute: {
          success: true,
        },
      });
    case disputeStamp.FAILURE:
      return Object.assign({}, state, {
        dispute: {
          error: action.payload,
        },
      });
    case getStamps.SUCCESS:
      return Object.assign({}, state, { stamps: action.payload.stamps });
    case getStamps.FAILURE:
      return state;
    default:
      return state;
  }
};
