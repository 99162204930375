import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { disputeStamp } from '../../../store/stamps/actions';

const DisputeStampPage = ({
  success,
  error,
  dispute,
  location: { search },
  match: { params: { stampId } },
}) => {
  useEffect(() => {
    const { email } = qs.parse(search);

    dispute(stampId, email);
  }, []);

  return (
    <div className="page-stamp-dispute">
      {success && <Alert color="success">Your dispute has been noted - this Stamp has not been verified. Please contact the sender and amend information. They will need to edit and issue the amended Stamp again in order for you to verify information.</Alert>}
      {error && <Alert color="danger">{error.message}</Alert>}
    </div>
  );
};

DisputeStampPage.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  dispute: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      stampId: PropTypes.string,
    }),
  }).isRequired,
};

DisputeStampPage.defaultProps = {
  success: null,
  error: null,
};

function mapStateToProps({ stamps: { dispute: { success, error } } }) {
  return { success, error };
}

function mapDispatchToProps(dispatch) {
  return {
    dispute: (stampId, emailAddress) => dispatch(disputeStamp({ stampId, emailAddress })),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(DisputeStampPage);
