import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../store/login/actions';

const errorMessage = (
  <p>
    Failed to logout. Please click <Link to="/logout">here</Link> to try again.
  </p>
);

export const LogoutPage = ({
  authenticated,
  performLogout,
  error,
}) => {
  useEffect(() => {
    performLogout();
  }, []);

  return (
    authenticated
      ? (
        <div className="page-logout">
          {error && <Alert color="danger">{errorMessage}</Alert>}
        </div>
      )
      : <Redirect to="/" />
  );
};

LogoutPage.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  performLogout: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

LogoutPage.defaultProps = {
  error: false,
};

function mapStateToProps({
  login: {
    authenticated,
    logout: {
      error,
    },
  },
}) {
  return {
    authenticated,
    error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    performLogout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
