import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { defineMessages } from 'react-intl';
import { push } from 'connected-react-router';
import {
  createStamp,
  verifyStamp,
  disputeStamp,
  getStamps,
} from './actions';
import getStampService from '../../services/stamp';
import { intl } from '../../containers/i18n';

const createStampMessages = defineMessages({
  InvalidEmailAddressException: {
    id: 'CreateStamp.Error.InvalidEmailAddress',
    defaultMessage: 'Invalid email address found in recipient list.',
  },
  InvalidPhoneNumberException: {
    id: 'CreateStamp.Error.InvalidPhoneNumber',
    defaultMessage: 'Invalid phone number found in recipient list.',
  },
  InvalidTimePeriodException: {
    id: 'CreateStamp.Error.InvalidTimePeriod',
    defaultMessage: 'A Stamp time period start date cannot be later than the end date',
  },
  NoMinutesException: {
    id: 'CreateStamp.Error.NoMinutes',
    defaultMessage: 'A Stamp must include a number of hours and minutes.',
  },
  NoRecipientsException: {
    id: 'CreateStamp.Error.NoRecipients',
    defaultMessage: 'A Stamp must include at least one recipient.',
  },
  NoStartDateException: {
    id: 'CreateStamp.Error.NoStartDate',
    defaultMessage: 'A Stamp time period start date is required.',
  },
  NoEndDateException: {
    id: 'CreateStamp.Error.NoEndDate',
    defaultMessage: 'A Stamp time period end date is required.',
  },
  UnrespondedStampException: {
    id: 'CreateStamp.Error.UnrespondedStamp',
    defaultMessage: 'Stamp cannot be issued. Employee needs to respond to the previous Stamp before this one can be issued.',
  },
  IssuerIsRecipientException: {
    id: 'CreateStamp.Error.IssuerIsRecipient',
    defaultMessage: 'A Stamp cannot be isssued for verification using the same email address as your own.',
  },
  generic: {
    id: 'CreateStamp.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const verifyStampMessages = defineMessages({
  generic: {
    id: 'VerifyStamp.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const disputeStampMessages = defineMessages({
  generic: {
    id: 'DisputeStamp.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

const getStampsMessages = defineMessages({
  generic: {
    id: 'GetStamps.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

export function* handleCreateStamp(action) {
  try {
    const stampService = getStampService();

    yield put(createStamp.request());

    const res = yield apply(stampService, stampService.create, [
      {
        ...action.payload.values,
        course: action.payload.props.course,
        module: action.payload.props.module,
      },
    ]);

    yield put(createStamp.success(res));
    yield put(push('/', {
      action: 'STAMP_CREATE',
    }));
  } catch (err) {
    const message = createStampMessages[err.code] || createStampMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(createStamp.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(createStamp.fulfill());
  }
}

export function* handleVerifyStamp(action) {
  try {
    const stampService = getStampService();

    yield put(verifyStamp.request());

    const res = yield apply(stampService, stampService.verify, [
      action.payload.stampId,
      action.payload.emailAddress,
    ]);

    yield put(verifyStamp.success(res));
  } catch (err) {
    const message = verifyStampMessages[err.code] || verifyStampMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(verifyStamp.failure(error));
  } finally {
    yield put(verifyStamp.fulfill());
  }
}

export function* handleDisputeStamp(action) {
  try {
    const stampService = getStampService();

    yield put(disputeStamp.request());

    const res = yield apply(stampService, stampService.dispute, [
      action.payload.stampId,
      action.payload.emailAddress,
    ]);

    yield put(disputeStamp.success(res));
  } catch (err) {
    const message = disputeStampMessages[err.code] || disputeStampMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(disputeStamp.failure(error));
  } finally {
    yield put(disputeStamp.fulfill());
  }
}

export function* handleGetStamps() {
  try {
    const stampService = getStampService();

    yield put(getStamps.request());

    const res = yield apply(stampService, stampService.get);

    yield put(getStamps.success(res));
  } catch (err) {
    const message = getStampsMessages[err.code] || getStampsMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(getStamps.failure(error));
  } finally {
    yield put(getStamps.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(createStamp.TRIGGER, handleCreateStamp),
    takeLatest(verifyStamp.TRIGGER, handleVerifyStamp),
    takeLatest(disputeStamp.TRIGGER, handleDisputeStamp),
    takeLatest(getStamps.TRIGGER, handleGetStamps),
  ]);
}
