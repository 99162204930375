import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ListCoursesManager({ courses }) {
  let coursesList;

  if (courses.length) {
    coursesList = (
      <ul>
        {courses.map(course => (
          <li key={course.id}>
            <Link to={`/courses/${course.id}`}>{course.name}</Link>
          </li>
        ))}
      </ul>
    );
  }
  return <>{coursesList}</>;
}

ListCoursesManager.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

ListCoursesManager.defaultProps = {
  courses: [],
};

export default ListCoursesManager;
