import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ResetPasswordForm from './form';

const ResetPasswordPage = ({ location }) => (
  <div className="page-reset-password">
    <h4 className="mb-4">
      <FormattedMessage id="ResetPassword.Title" defaultMessage="Reset your password" />
    </h4>
    <FormattedMessage
      id="ResetPassword.Advice"
      tagName="p"
      defaultMessage="Please check your emails and enter the password reset code along with a new password when you receive it."
    />
    <ResetPasswordForm email={location.state && location.state.email} />
  </div>
);

ResetPasswordPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default connect()(ResetPasswordPage);
