import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormGroup, Alert } from 'reactstrap';
import {
  reduxForm,
  Field,
  formValueSelector,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { forgotPasswordFormHandler } from '../../../store/forgot-password/actions';
import FormInput from '../../../components/form-input';
import { requiredField } from '../../../utils/validation';

const ForgotPasswordForm = ({
  handleSubmit,
  pristine,
  error,
  invalid,
  submitting,
  intl,
}) => (
  <form onSubmit={handleSubmit(forgotPasswordFormHandler)}>
    {error && <Alert color="danger">{error.message}</Alert>}
    <FormGroup>
      <Field
        component={FormInput}
        name="email"
        id="email"
        type="text"
        bsSize="lg"
        validate={[requiredField]}
        placeholder={intl.formatMessage({
          id: 'ForgotPassword.Form.Email',
          defaultMessage: 'Email',
        })}
        required
      />
    </FormGroup>
    <Button
      type="submit"
      size="lg"
      disabled={invalid || pristine || submitting}
      color="primary"
      block
    >
      <FormattedMessage id="ForgotPassword.Form.Send" defaultMessage="Submit" />
    </Button>
  </form>
);

ForgotPasswordForm.propTypes = {
  ...reduxFormPropTypes,
};

// Enhance the form component with redux-form. This connects the form to the
// store.
const withReduxForm = reduxForm({
  form: 'ForgotPassword.Form',
})(ForgotPasswordForm);

// Connect the form component to the Redux store to allow us to get access to
// the redux-form field values. We use this to persist the email address through
// to the reset password page.
const selector = formValueSelector('ForgotPassword.Form');
const withConnect = connect(state => ({
  emailValue: selector(state, 'email'),
}))(withReduxForm);

// Enhance the enhanced form component to provide i18n APIs.
const withIntl = injectIntl(withConnect);

export default withIntl;
