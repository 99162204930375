import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import LoginForm from './form';

export const LoginPage = ({ location }) => {
  // Take the location the user has come from. Upon successful sign-in the user
  // is redirected back to the original page. If the user navigates straight to
  // the login page and is already authenticated they are redirected to the
  // homepage.
  const params = new URLSearchParams(location.search);
  let email = params.get('email');
  let from = '/';
  let action;

  if (location.state) {
    from = location.state.from || '/';
    action = location.state.action; // eslint-disable-line prefer-destructuring
    email = location.state.email; // eslint-disable-line prefer-destructuring
  }

  let message;

  switch (action) {
    case 'RESET_PASSWORD':
      message = (
        <Alert color="success">
          <FormattedMessage
            id="Login.Alert.PasswordChanged"
            defaultMessage="Your password has been changed successfully."
          />
        </Alert>
      );
      break;
    case 'CONFIRM_EMAIL':
      message = (
        <Alert color="success">
          <FormattedMessage
            id="Login.Alert.EmailAddressConfirmed"
            defaultMessage="Your email address has been confirmed successfully."
          />
        </Alert>
      );
      break;
    case 'CONFIRM_PASSWORD':
      message = (
        <Alert color="success">
          <FormattedMessage
            id="Login.Alert.PasswordConfirmed"
            defaultMessage="Your password has been set successfully."
          />
        </Alert>
      );
      break;
    default:
      message = null;
  }

  return (
    <div className="page-login">
      <FormattedMessage
        id="Login.Title"
        defaultMessage="Sign in"
      >
        {(...chunks) => <h4 className="mb-4">{chunks}</h4>}
      </FormattedMessage>
      {message}
      <LoginForm email={email} from={from} />
      <FormattedMessage
        id="Login.ForgottenPassword"
        defaultMessage="Forgotten your password? {link} now."
        values={{
          link: (
            <Link to="/forgotten-password">
              <FormattedMessage
                id="Login.ForgottenPassword.Link"
                defaultMessage="Reset it"
              />
            </Link>
          ),
        }}
      />
      <div className="d-inline-block border-top py-4 mt-4">
        <FormattedMessage
          id="Login.Register"
          defaultMessage="Don't have an account? {link} now."
          values={{
            link: (
              <Link to="/register">
                <FormattedMessage
                  id="Login.Register.Link"
                  defaultMessage="Sign up"
                />
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      action: PropTypes.string,
      email: PropTypes.string,
      from: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default connect()(LoginPage);
