import {
  all,
  apply,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { getManagers } from './actions';
import getUserService from '../../services/user';
import { intl } from '../../containers/i18n';

const getManagersMessages = defineMessages({
  generic: {
    id: 'GetManagers.Error.Generic',
    defaultMessage: 'Something went wrong.',
  },
});

export function* handleGetManagers() {
  try {
    const userService = getUserService();

    yield put(getManagers.request());

    const res = yield apply(userService, userService.get);

    yield put(getManagers.success(res));
  } catch (err) {
    const message = getManagersMessages.generic;
    const error = new Error(intl.formatMessage(message));

    yield put(getManagers.failure(error));
  } finally {
    yield put(getManagers.fulfill());
  }
}

export default function* watch() {
  yield all([
    takeLatest(getManagers.TRIGGER, handleGetManagers),
  ]);
}
