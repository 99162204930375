import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  Progress,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getStamps } from '../../../store/stamps/actions';
import { getCourses } from '../../../store/courses/actions';
import ToggleButtonGroup from '../../../components/toggle-button-group';

const EmployeeDashboard = ({
  getStamps, // eslint-disable-line no-shadow
  getCourses, // eslint-disable-line no-shadow
  stamps,
  courses,
}) => {
  const [selectedDashboardTab, changeSelectedDashboardTab] = useState(0);

  useEffect(() => {
    getStamps();
    getCourses();
  }, []);

  // Render a list of available courses.
  let coursesList;

  if (courses.length) {
    coursesList = (
      <div className="courses box p-3 p-sm-5">
        <FormattedMessage
          id="Dashboard.Employee.Courses"
          defaultMessage="Your courses"
          tagName="h2"
        />
        <ul>
          {courses.map(course => (
            <li key={course.id}>
              <Link to={`/courses/${course.id}`}>{course.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // If no stamps have been approved we render a call to action to encourage the
  // sending of a stamp.
  const cta = !stamps.length ? (
    <FormattedMessage
      id="Dashboard.Employee.EmptyAdvice"
      tagName="p"
      defaultMessage="Once your manager begins to confirm Stamps you have sent them you will see an overview of your progress on this page. Get started by {link} or {courseLink}."
      values={{
        link: (
          <Link to="/stamps/new">
            <FormattedMessage
              id="Dashboard.Employee.EmptyAdvice.Link"
              defaultMessage="sending a Stamp"
            />
          </Link>
        ),
        courseLink: (
          <Link to="/courses">
            <FormattedMessage
              id="Dashboard.Employee.EmptyAdvice.CoursesLink"
              defaultMessage="enroling in a Course"
            />
          </Link>
        ),
      }}
    />
  ) : null;

  // If stamps have been sent and approved we render a summary.
  let summary;

  if (stamps.length) {
    const skills = stamps.reduce((arr, stamp) => arr.concat(stamp.skills), []);
    const totalTime = stamps.reduce((num, stamp) => num + +stamp.minutes, 0);
    const uniqueSkills = [...new Set(skills)];
    const hours = Math.floor(totalTime / 60);
    let minutes = totalTime % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    summary = (
      <React.Fragment>
        <Row>
          <Col>
            <ToggleButtonGroup
              buttonText={[
                <FormattedMessage id="Dashboard.Employee.ToggleSkills" defaultMessage="Skills" />,
                <FormattedMessage id="Dashboard.Employee.ToggleHours" defaultMessage="Hours" />,
              ]}
              selectedIndex={selectedDashboardTab}
              onChange={changeSelectedDashboardTab}
              block
            />
          </Col>
        </Row>
        <Card style={{ display: selectedDashboardTab === 0 ? 'block' : 'none' }}>
          <CardBody>
            <div className="tag-group">
              {uniqueSkills.map(skill => (
                <div key={skill} className="tag-item">{skill}</div>
              ))}
            </div>
          </CardBody>
        </Card>
        <Card style={{ display: selectedDashboardTab === 1 ? 'block' : 'none' }}>
          <CardBody>
            <Progress value={totalTime} max={totalTime}>
              {hours}:{minutes}
            </Progress>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <div className="page-dashboard-employee">
      {coursesList}
      {cta}
      {summary}
    </div>
  );
};

EmployeeDashboard.propTypes = {
  getStamps: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  stamps: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
  })),
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

EmployeeDashboard.defaultProps = {
  stamps: [],
  courses: [],
};

function mapStateToProps({ stamps: { stamps }, courses: { courses } }) {
  return {
    stamps: stamps.filter(stamp => stamp.status === 'verified'),
    courses: courses.filter(course => course.enrolmentStatus === 'verified'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStamps: () => dispatch(getStamps()),
    getCourses: () => dispatch(getCourses()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);
