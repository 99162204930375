import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const CourseListItem = ({ course, handleEnrolment }) => {
  let courseLink;
  let cta;

  switch (course.enrolmentStatus) {
    case 'unenrolled':
      cta = (
        <Button color="primary" block onClick={() => handleEnrolment(course.id)}>
          Start
        </Button>
      );
      break;
    case 'sent':
      courseLink = (
        <Link className="btn btn-secondary btn-block disabled" to={`/courses/${course.id}`}>
          View course
        </Link>
      );
      cta = (
        <p className="enrolment-status-sent">
          Awaiting approval from {course.manager}
        </p>
      );
      break;
    case 'verified':
      courseLink = (
        <Link className="btn btn-secondary btn-block" to={`/courses/${course.id}`}>
          View course
        </Link>
      );
      break;
    default:
      cta = null;
  }

  return (
    <Card>
      <CardBody>
        <CardTitle><h5>{course.name}</h5></CardTitle>
        <CardSubtitle className="mb-2 text-muted font-italic">from {course.organisation}</CardSubtitle>
        <CardText>{course.description}</CardText>
        <CardText>
          <div className="tag-group">
            <div className="tag-item dark">about {course.minutes / 60} hours</div>
          </div>
        </CardText>
        {courseLink}
        {cta}
      </CardBody>
    </Card>
  );
};

CourseListItem.propTypes = {
  handleEnrolment: PropTypes.func,
  course: PropTypes.shape({
    enrolmentStatus: PropTypes.string,
    manager: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    organisation: PropTypes.string,
    minutes: PropTypes.number,
  }).isRequired,
};

CourseListItem.defaultProps = {
  handleEnrolment: null,
};

export default CourseListItem;
