import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import GA from 'react-ga';
import OrganisationRegisterForm from './form-organisation';
import EmployeeRegisterForm from './form-employee';

const RegisterPage = () => {
  const [selectedFormTab, changeSelectedFormTab] = useState(0);

  function handleChangeFormTab(i) {
    changeSelectedFormTab(i);
    GA.event({
      category: 'User',
      action: 'Selected registration tab',
      label: i === 0 ? 'Employee' : 'Organisation',
    });
  }

  return (
    <div className="page-register">
      <h4 className="mb-4">
        <FormattedMessage id="Register.Title" defaultMessage="Create an account" />
      </h4>
      <div className="radio-group">
        <label className="custom-radio" htmlFor="register-employee">
          <input
            type="radio"
            name="registration"
            id="register-employee"
            value="0"
            checked={selectedFormTab === 0}
            onChange={() => handleChangeFormTab(0)}
          />
          <span>
            <FormattedMessage id="Register.Employee" defaultMessage="Employee" />
            <br />
            <i>
              <FormattedMessage id="Register.Employee.Advice" defaultMessage="Your hours and progress are recorded by your employer." />
            </i>
          </span>
        </label>
        <label className="custom-radio" htmlFor="register-organisation">
          <input
            type="radio"
            name="registration"
            id="register-organisation"
            value="1"
            checked={selectedFormTab === 1}
            onChange={() => handleChangeFormTab(1)}
          />
          <span>
            <FormattedMessage id="Register.Organisation" defaultMessage="Organisation" />
            <br />
            <i>
              <FormattedMessage id="Register.Organisation.Advice" defaultMessage="You organise employees by tracking hours and guiding their progress." />
            </i>
          </span>
        </label>
      </div>
      {selectedFormTab === 0 && <EmployeeRegisterForm />}
      {selectedFormTab === 1 && <OrganisationRegisterForm />}
      <div className="d-inline-block border-top py-4">
        <FormattedMessage
          id="Register.Login"
          defaultMessage="By signing up, you agree to Tendo's {legal}. Already have an account? {link} now."
          values={{
            legal: (
              <a href="https://www.tendo.com/legal">
                <FormattedMessage
                  id="Register.Legal.Link"
                  defaultMessage="legal policy"
                />
              </a>
            ),
            link: (
              <Link to="/login">
                <FormattedMessage
                  id="Register.Login.Link"
                  defaultMessage="Sign in"
                />
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default connect()(RegisterPage);
