import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, injectIntl } from 'react-intl';
import translations from '../../translations.json'; // eslint-disable-line import/no-unresolved

// This export is a bit of a hack to allow us to use the intl context outside of
// React components (such as inside a Redux reducer or action creator).
//
// See https://github.com/formatjs/react-intl/issues/416.
//
export let intl; // eslint-disable-line import/no-mutable-exports

const GlobalIntlProvider = injectIntl(class extends React.Component {
  constructor(...args) {
    super(...args);
    intl = this.props.intl; // eslint-disable-line max-len, prefer-destructuring, react/destructuring-assignment, react/prop-types
  }

  render() {
    const { children } = this.props;

    return children;
  }
});

// Configure the intl provider with the given locale. The intl provider is
// passed down to the child component as a prop so we can use the component
// defined above to pull it out and make it available outside of the React
// context.
const I18nProvider = ({ defaultLocale, locale, children }) => {
  const selectedLocale = locale || defaultLocale;
  const messages = translations[selectedLocale];

  return (
    <IntlProvider
      locale={selectedLocale}
      messages={messages}
      key={selectedLocale}
    >
      <GlobalIntlProvider>
        {children}
      </GlobalIntlProvider>
    </IntlProvider>
  );
};

I18nProvider.propTypes = {
  defaultLocale: PropTypes.string,
  locale: PropTypes.string,
};

I18nProvider.defaultProps = {
  defaultLocale: 'en',
  locale: null,
};

function mapStateToProps({ i18n }) {
  return {
    locale: i18n.locale,
  };
}

export default connect(mapStateToProps)(I18nProvider);
