import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ForgotPasswordForm from './form';

const ForgotPasswordPage = () => (
  <div className="page-forgot-password">
    <h4 className="mb-4">
      <FormattedMessage id="ForgotPassword.Title" defaultMessage="Forgotten your password?" />
    </h4>
    <FormattedMessage
      id="ForgotPassword.Advice1"
      tagName="p"
      defaultMessage="Please enter the email address used when registering your account."
    />
    <FormattedMessage
      id="ForgotPassword.Advice2"
      tagName="p"
      defaultMessage="You will receive an email containing a code that you will need to enter on the next page to complete the password reset process."
    />
    <ForgotPasswordForm />
    <div className="d-inline-block border-top py-4 mt-4">
      <FormattedMessage id="ForgotPassword.Login" defaultMessage="Back to login.">
        {text => <React.Fragment><Link to="/login">{text}</Link></React.Fragment>}
      </FormattedMessage>
    </div>
  </div>
);

export default connect()(ForgotPasswordPage);
